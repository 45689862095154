export class JWTTokenModel {
  /** @type {String} */
  accessToken;
  /** @type {String} */
  refreshToken;

  constructor({ accessToken, refreshToken }) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
  }
}

export class UserModel {
  /** @type {String} */
  name;
  /** @type {String} */
  code;

  constructor({ name, code }) {
    this.name = name;
    this.code = code;
  }
}
