const debug = true;

function logger(loggerFn, message) {
  if (message && debug) {
    loggerFn(
      "%c 908" + " %c Debug ",
      "background: #900; color: #fff; padding: 1px; border-radius: 3px 0 0 3px;",
      "background: #bada55; color: #000; padding: 1px; border-radius: 0 3px 3px 0;",
      message
    );
  }
}

function log(message) {
  logger(console.log, message);
}

function warn(message) {
  logger(console.warn, message);
}

function error(message) {
  logger(console.error, message);
}

export default {
  log,
  warn,
  error,
};
