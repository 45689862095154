import EUSign from "../../public/js/eusign/eusign";

import {
  KeysContainerModel,
  CAServerListModel,
  EndUserOwnerInfoModel,
  KeyInfoBinaryAndCerificatesModel,
} from "../models/signature";

let cryptoLibrary = {};

const store = {
  CA: new CAServerListModel([]),
};

export async function initializeCryptoLibrary() {
  cryptoLibrary = new EUSign();

  new Promise((res, rej) => {
    cryptoLibrary.initialize(onSuccess, onError);

    function onSuccess() {
      return res();
    }
    function onError(error) {
      return rej(error);
    }
  });
}

export async function moduleFullInitialization() {
  return new Promise((res, rej) => {
    const checkLibraryInitialized = setInterval(() => {
      if (cryptoLibrary.isInitialized()) {
        clearInterval(checkLibraryInitialized);
        res();
      }
    }, 50);
  });
}

export async function getJKSPrivateKeysFromFile(fileObject) {
  return new Promise((res, rej) => {
    cryptoLibrary.enumJKSPrivateKeys(fileObject, onSuccess, onError);

    function onSuccess(keysContainer) {
      const { keys } = new KeysContainerModel(keysContainer);
      return res(keys);
    }
    function onError() {
      return rej();
    }
  });
}

export async function readPrivateKeyFile(file, password, CAServer) {
  const certificatesFiles = null;

  return new Promise((res, rej) => {
    cryptoLibrary.readPrivateKeyFile(
      file,
      password,
      certificatesFiles,
      CAServer,
      onSuccess,
      onError
    );

    function onSuccess(keyInfo) {
      return res(new EndUserOwnerInfoModel(keyInfo));
    }
    function onError(error) {
      return rej(error);
    }
  });
}

export async function readJKSPrivateKeyFile(file, key, password) {
  return new Promise((res, rej) => {
    cryptoLibrary.getJKSPrivateKey(file, key, password, onSuccess, onError);

    function onSuccess(keyInfo) {
      return res(new EndUserOwnerInfoModel(keyInfo));
    }
    function onError(error) {
      return rej(error);
    }
  });
}

export async function CtxSignFile(file, key, password, attachment) {
  const singatureAlgorithm = {
    nameInCryptoLibrary: "EU_CTX_SIGN_DSTU4145_WITH_GOST34311",
    value: 1,
  };
  const isExternal = false;
  const appendCert = true;

  return new Promise((res, rej) => {
    cryptoLibrary.ctxSignFile(
      file,
      key,
      password,
      singatureAlgorithm.value,
      attachment,
      isExternal,
      appendCert,
      attachment.name,
      onSuccess,
      onError
    );

    function onSuccess(uint8arraySignDocument) {
      return res(uint8arraySignDocument);
    }
    function onError(error) {
      return rej(error);
    }
  });
}

export async function getKeyInfoBinaryAndCerificates(file, key, password) {
  const cmpServers = [
    "acsk.privatbank.ua:80",
    "ca.informjust.ua:80",
    "acskidd.gov.ua:80",
    "csk.uz.gov.ua:80",
    "masterkey.ua:80",
    "ca.ksystems.com.ua:80",
    "csk.uss.gov.ua:80",
    "csk.ukrsibbank.com:80",
    "ca.mil.gov.ua:80",
    "acsk.dpsu.gov.ua:80",
    "acsk.er.gov.ua:80",
    "ca.mvs.gov.ua:80",
    "canbu.bank.gov.ua:80",
    "uakey.com.ua:80",
    "ca.csd.ua/public/x509/cmp:80",
    "ca.gp.gov.ua/cmp:80",
  ];

  return new Promise((res, rej) => {
    cryptoLibrary.GetKeyInfoBinaryAndCertificates(
      file,
      key,
      password,
      cmpServers,
      onSuccess,
      onError
    );

    function onSuccess(response) {
      return res(new KeyInfoBinaryAndCerificatesModel(response));
    }
    function onError(error) {
      return rej(error);
    }
  });
}

export function setCA(serverIndexOrServer) {
  if (typeof serverIndexOrServer === "number") {
    const servers = __getCAsList();
    cryptoLibrary.setCA(servers[serverIndexOrServer]);
  } else {
    cryptoLibrary.setCA(serverIndexOrServer);
  }
}

export function findServerIndexByIssuerCN(issuerCN) {
  const servers = __getCAsList();
  return servers.findIndex((server, i) => {
    return servers[i].issuerCN === issuerCN;
  });
}

export function getIssuerCNByIndex(serverIndex) {
  const serversList = __getCAsList();
  return serversList[serverIndex].issuerCN;
}

export function getCAServerByIndex(serverIndex) {
  const serversList = __getCAsList();
  return serversList[serverIndex];
}

function __getCAsList() {
  if (store.CA.servers.length === 0) {
    store.CA = new CAServerListModel(cryptoLibrary.getCAs());
  }
  return store.CA.servers;
}

export default cryptoLibrary;
