<template lang="pug">
transition(name="expand", @after-enter="afterEnter", @enter="enter", @leave="leave")
  slot
</template>

<script>
export default {
  methods: {
    afterEnter(el) {
      el.style.height = "auto";
    },
    enter(el) {
      const { width } = getComputedStyle(el);
      el.style.width = width;
      el.style.position = "absolute";
      el.style.visibility = "hidden";
      el.style.height = "auto";

      const { height } = getComputedStyle(el);

      el.style.width = null;
      el.style.position = null;
      el.style.visibility = null;
      el.style.height = 0;

      getComputedStyle(el).height;
      requestAnimationFrame(() => {
        el.style.height = height;
      });
    },
    leave(el) {
      const { height } = getComputedStyle(el);

      el.style.height = height;

      getComputedStyle(el).height;
      requestAnimationFrame(() => {
        el.style.height = 0;
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.expand-enter-active, .expand-leave-active
  transition: height 250ms ease-in-out
  overflow: hidden
  transition-property: opacity, height

.expand-enter, .expand-leave-to
  height: 0
  opacity: 0
</style>
