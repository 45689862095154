export class KeysContainerModel {
  /** @type {Key[]} */
  keys;

  constructor(keys) {
    this.keys = keys.map((key) => new Key(key));
  }
}

class Key {
  /** @type {String} */
  name;
  /** @type {String} */
  alias;

  constructor({ name, alias }) {
    this.name = name;
    this.alias = alias;
  }
}

export class CAServerListModel {
  /** @type {CAServer[]} */
  servers;

  constructor(servers) {
    this.servers = servers.map((server) => new CAServer(server));
  }
}

class CAServer {
  /** @type {String} */
  issuerCN;
  /** @type {Boolean} */
  loadPKCertsFromFile;

  constructor(server) {
    this.issuerCN = server.issuerCN;
    this.loadPKCertsFromFile = server.loadPKCertsFromFile;
  }
}

export class EndUserOwnerInfoModel {
  /** @type {String} */
  serial;
  /** @type {String} */
  issuerCN;
  /** @type {String} */
  subjOrg;

  constructor(keyInfo) {
    if (this.isJKS(keyInfo)) {
      this.serial = keyInfo.keyInfo.serial;
      this.issuerCN = keyInfo.keyInfo.issuerCN;
      this.subjOrg = keyInfo.keyInfo.subjOrg;
    } else {
      this.serial = keyInfo.serial;
      this.issuerCN = keyInfo.issuerCN;
      this.subjOrg = keyInfo.subjOrg;
    }
  }

  isJKS(keyInfo) {
    return !!keyInfo.keyInfo;
  }
}

export class KeyInfoBinaryAndCerificatesModel {
  /** @type {Uint8Array} */
  certificates;
  /** @type {Uint8Array} */
  publicKeyInformation;
  /** @type {Uint8Array} */
  userCertificatePublicKeyInformation;

  constructor({ certificates, publicKeyInformation, userCertificatePublicKeyInformation }) {
    this.certificates = certificates;
    this.publicKeyInformation = publicKeyInformation;
    this.userCertificatePublicKeyInformation = userCertificatePublicKeyInformation;
  }
}
