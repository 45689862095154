<template lang="pug">
transition(:name="name", :mode="mode")
  slot
</template>

<script>
export default {
  props: {
    direction: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "in-out",
    },
  },
  computed: {
    name() {
      return this.direction.length > 0 ? "slide" + "-" + this.direction : "slide";
    },
  },
};
</script>

<style lang="sass">
.slide-left-enter-active,
.slide-left-leave-active
  transition: all 200ms ease
  opacity: 1

.slide-left-enter-from
  transform: translateX(1rem)
  opacity: 0

.slide-left-leave-to
  transform: translateX(-1rem)
  opacity: 0

.slide-right-enter-active,
.slide-right-leave-active
  transition: all 200ms ease

.slide-right-enter-from
  transform: translateX(-1rem)
  opacity: 0

.slide-right-leave-to
  transform: translateX(1rem)
  opacity: 0
</style>
