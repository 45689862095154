import store from "@/store";

export function auth(to, from, next) {
  if (store.getters["auth/isUserAuthorized"]) {
    next();
  } else {
    next("/");
  }
}

export function isLoggedIn(to, from, next) {
  if (store.getters["auth/isUserAuthorized"]) {
    next("/docs");
  } else {
    next();
  }
}
