import http, { docsApiBaseUrl } from "./http-client";
import HttpError from "./http-error";

import { Attachment } from "@/models/document";

const Api = {
  DOCS: "api/v2/docs",
};

export function getLinkToOriginalFile(id) {
  return docsApiBaseUrl + "/download/" + id + "?type=original";
}

export async function createDocument(document) {
  try {
    const response = await http.post(Api.DOCS, document, {
      timeout: 0,
    });
    return response.data;
  } catch (error) {
    throw new HttpError(error);
  }
}

export async function downloadDocument(path) {
  try {
    return await http.get(path, {
      timeout: 0,
    });
  } catch (error) {
    throw new HttpError(error);
  }
}

export async function markDocumentAsRead(id) {
  try {
    return await http.put(Api.DOCS + "/" + id, {
      isRead: true,
    });
  } catch (error) {
    throw new HttpError(error);
  }
}

export async function markDocumentAsUnread(id) {
  try {
    return await http.put(Api.DOCS + "/" + id, {
      isRead: false,
    });
  } catch (error) {
    throw new HttpError(error);
  }
}

export async function setDocumentAsPublic(id) {
  try {
    return await http.put(Api.DOCS + "/" + id, {
      public: true,
    });
  } catch (error) {
    throw new HttpError(error);
  }
}

export async function setDocumentAsPrivate(id) {
  try {
    return await http.put(Api.DOCS + "/" + id, {
      public: false,
    });
  } catch (error) {
    throw new HttpError(error);
  }
}

export async function moveDocumentTo(id, folder) {
  try {
    return await http.put(Api.DOCS + "/" + id, {
      folder,
    });
  } catch (error) {
    throw new HttpError(error);
  }
}

export async function removeDocument(id) {
  try {
    return await http.delete(Api.DOCS + "/" + id);
  } catch (error) {
    throw new HttpError(error);
  }
}

export async function getDocument(id) {
  try {
    const response = await http.get(Api.DOCS + "/" + id);
    return response.data.document;
  } catch (error) {
    throw new HttpError(error);
  }
}

export async function getDocuments(params) {
  try {
    return await http.get(Api.DOCS, {
      params,
    });
  } catch (error) {
    throw new HttpError(error);
  }
}

export async function getDocumentAttachments(id) {
  try {
    const response = await http.get(Api.DOCS + "/" + id + "/attachments/signature-option", {
      timeout: 0,
      headers: {
        "content-type": "application/json",
      },
    });
    return new Attachment(response.data);
  } catch (error) {
    throw new HttpError(error);
  }
}

export async function signDocument(id, { file, serial, authority, organization }) {
  try {
    const response = await http.post(
      Api.DOCS + "/" + id + "/attachments/save",
      {
        file,
        serial,
        authority,
        organization,
      },
      {
        timeout: 0,
        headers: {
          "content-type": "application/json",
        },
      }
    );
    return response.data.document;
  } catch (error) {
    throw new HttpError(error);
  }
}
