<template lang="pug">
button.spinner(ref="button", :disabled="disabled", :type="type")
  .spin
    .spinner-border.spinner-border-sm
  span.slot
    slot
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "submit",
    },
  },
};
</script>

<style lang="sass" scoped>
button.spinner
  position: relative
  .slot
    transition: padding 250ms ease-in-out

  .spin
    position: absolute
    transition: opacity 250ms ease-in-out
    opacity: 0

  &:disabled
    .spin
      opacity: 1

    .slot
      padding-left: 1.5rem
</style>
