import { httpClientOpendatabot } from "./http-client";

const Api = {
  v3: {
    CHECK: "api/v3/check",
  },
};

const apiKey = "e8SPJxUps6WP";

export async function getPersonOrCompanyInfo(code) {
  const response = await httpClientOpendatabot.get(Api.v3.CHECK + "/" + code, {
    params: {
      apiKey,
    },
  });
  return response.data.data;
}
