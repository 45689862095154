<template lang="pug">
transition-slide
  section(v-if="error")
    .alert.alert-warning.m-0
      fa-icon.me-2(:icon="['far', 'exclamation-triangle']", aria-hidden="true")
      | {{ error }}
    .p-1
</template>

<script>
import Slide from "@/components/transitions/Slide";

export default {
  props: {
    error: {
      type: String,
      default: undefined,
    },
  },
  components: {
    "transition-slide": Slide,
  },
};
</script>
