<template lang="pug">
div
  docs-authorization-form(
    :is-shown="isAuthFormShown",
    closeable,
    @close="closeAuthorizationForm",
    @authorized="onAuthorizedSuccessfuly"
  )
  .container.bg-light(ref="app")
    .p-5.mb-4.pt-3.rounded-0(
      style="background-color: #266fbd; border-radius: 0 0 0.25rem 0.25rem !important"
    )
      .row.justify-content-center
        .col-12.col-md-6.align-self-center.order-md-1
          .cls-container(style="padding-top: 52.5%")
            img.cls(src="https://pipe.bot/img/d/m/48/f497ac.png", alt="Документообіг")
          .text-center
            img(
              src="https://opendatabot.ua/_nuxt/img/bc0260d.png",
              alt="Опендатабот",
              width="166",
              height="32"
            )

        .col.text-center.text-white
          .p-md-2
          h1.font-weight-bolder Документообіг
          p.lead Легкий, швидкий, мобільний
          button.btn.btn-light.btn-lg(@click="login", @keyup.esc="isAuthFormShown = false")
            fa-icon(:icon="['far', 'signature']")
            span.ms-1 Увійти з ЕЦП
          .p-4 або встановіть застосунок
          a(
            href="https://apps.apple.com/app/id1519215299",
            ref="iOSLink",
            @click="sendEventInstall('iOS')"
          )
            img.m-1(
              src="https://pipe.bot/img/d/m/d0/558d91.svg",
              height="40",
              alt="Документообіг для iOS"
            )
          a(
            href="https://play.google.com/store/apps/details?id=com.inc_908.document_exchange",
            ref="androidLink",
            @click="sendEventInstall('Android')"
          )
            img.m-1(
              src="https://pipe.bot/img/d/m/56/b44686.svg",
              height="40",
              alt="Документообіг для Android"
            )

    .row
      .col-12.col-md-6.col-lg-4.mb-4(v-for="(feature, idx) in featureList")
        .card.border-0.h-100
          .card-body
            fa-icon.fa-2x(
              :icon="['fad', feature.icon]",
              style="--fa-primary-color: #ffc107; --fa-secondary-color: #007bff; --fa-secondary-opacity: 8"
            )
            .p-1
            h5.card-title {{ feature.title }}
            p.card-text {{ feature.text }}
    .p-2
</template>

<script>
import AuthorizationForm from "@/components/AuthorizationForm";

export default {
  components: {
    "docs-authorization-form": AuthorizationForm,
  },
  data() {
    return {
      isAuthFormShown: false,
      featureList: [
        {
          icon: "mobile",
          color: "primary",
          title: "Документи в смартфоні",
          text: "Документообіг від Опендатабота\xa0— це\xa0перша українська система для документообігу малого та\xa0середнього бізнесу, яка працює у\xa0мобільних телефонах.",
        },
        {
          icon: "handshake",
          color: "primary",
          title: "Контрагенти",
          text: "Завдяки прямій інтеграції з даними Опендатабота ви моментально отримаєте актуальну інформацію про контрагента. Для цього потрібно ввести код компанії чи ІПН фоп.",
        },
        {
          icon: "sort-size-up",
          color: "primary",
          title: "Фільтри",
          text: "Зручне сортування та\xa0фільтри дають змогу «на\xa0ходу» аналізувати та\xa0підписувати документи за\xa0допомогою ЕЦП.",
        },
        {
          icon: "file-signature",
          color: "primary",
          title: "ЕЦП",
          text: "Підписувати документи, надсилати на\xa0підпис договори та\xa0слідкувати за\xa0їх\xa0станом в\xa0режимі реального часу директору можно на\xa0iOS чи\xa0Android\xa0— вже не\xa0потрібно надавати бухгалтеру ЕЦП компанії.",
        },
        {
          icon: "database",
          color: "primary",
          title: "АРІ",
          text: "Розробникам доступне просте АРІ, яке можна використовувати для інтеграції з внутрішніми системами підприємства",
        },
        {
          icon: "abacus",
          color: "primary",
          title: "Діловодство",
          text: "Документообіг від Опендатабот – найпростіший спосіб для переходу на електронне діловодство.",
        },
      ],
    };
  },
  methods: {
    login() {
      if (this.$store.getters["auth/isUserAuthorized"]) {
        this.$router.push("/docs");
      } else {
        this.isAuthFormShown = true;
      }
    },
    sendEventInstall(action) {
      ga("send", "event", "Install", action);
    },
    closeAuthorizationForm() {
      this.isAuthFormShown = false;
    },
    onAuthorizedSuccessfuly() {
      this.isAuthFormShown = false;
      this.$router.push("/docs");
    },
  },
};
</script>

<style lang="sass" scoped>
.cls-container
  position: relative
  overflow: hidden
  height: 0

.cls
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%

[v-cloak]
  display: none
</style>
