import http from "./http-client";
import HttpError from "./http-error";

const Api = {
  v2: {
    AUTH: "api/v2/auth",
    LOGOUT: "api/v2/logout",
    TOKEN: "api/v2/token",
  },
};

export async function authenticateUserJWT(certificates) {
  try {
    return await http.post(
      Api.v2.AUTH + "/authentication",
      { certificates },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
  } catch (error) {
    throw new HttpError(error);
  }
}

export async function refreshToken(refreshToken) {
  try {
    return await http.post(Api.v2.TOKEN + "/refresh", {
      refreshToken,
    });
  } catch (error) {
    throw new HttpError(error);
  }
}

export async function authorizeUser() {
  try {
    return await http.get(Api.v2.AUTH + "/authorization");
  } catch (error) {
    throw new HttpError(error);
  }
}

export async function logoutUser() {
  try {
    return await http.get(Api.v2.LOGOUT);
  } catch (error) {
    throw new HttpError(error);
  }
}
