<template lang="pug">
section
  button.d-block.d-md-none.btn.rounded(type="button", @click.stop="toggleMenu", ref="folder")
    fa-icon.fa-fw.fa-lg(:icon="['far', 'bars']")
  transition(
    enter-active-class="animated fadeInDown superfaster",
    leave-active-class="animated fadeOutUp superfaster"
  )
    ul.dropdown-menu.dropdown-menu-end.show.shadow.border-0(
      v-show="isShown",
      v-click-outside="{ exclude: ['folder'], handler: 'closeDropdown'}"
    )
      li
        h6.dropdown-header Папки
      li
        router-link.dropdown-item(
          v-for="item in menu.folders",
          :key="item.name",
          :to="{ path: '/docs', query: { folder: item.folder } }",
          @click.prevent="closeDropdown"
        )
          fa-icon.me-2(:icon="['far', 'check']", v-show="$route.query.folder === item.folder")
          | {{ item.name }}

      li
        h6.dropdown-header Фільтри
      li
        router-link.dropdown-item(
          v-for="item in menu.filters",
          :key="item.name",
          :to="{ path: '/docs', query: { folder: $route.query.folder, filter: item.filter } }",
          @click.prevent="closeDropdown"
        )
          fa-icon.me-2(:icon="['far', $route.query.filter === item.filter ? 'dot-circle' : 'circle']")
          | {{ item.name }}

      li
        .dropdown-divider

      li
        a.dropdown-item(:href="menu.exit.path", @click.prevent="logout")
          fa-icon.me-2(:icon="['far', menu.exit.icon]")
          | {{ menu.exit.name }}
</template>

<script>
export default {
  emits: ["logout"],
  data() {
    return {
      isShown: false,
      menu: {
        folders: [
          { name: "Всі", folder: undefined },
          { name: "Улюблені", folder: "favorites" },
          { name: "Архів", folder: "archive" },
        ],
        filters: [
          { name: "На підпис", filter: "unsigned" },
          { name: "Підписані", filter: "signed" },
          { name: "Вхідні", filter: "inbound" },
          { name: "Вихідні", filter: "outbound" },
        ],
        exit: {
          name: "Вийти",
          icon: "sign-out",
          path: "/logout",
        },
      },
    };
  },
  methods: {
    logout() {
      this.closeDropdown();
      this.$emit("logout");
    },
    selectAndCloseMenu(eventName) {
      this.closeDropdown();
      this.$emit(eventName);
    },
    toggleMenu() {
      this.isShown = !this.isShown;
    },
    closeDropdown() {
      this.isShown = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.dropdown-menu-end
  right: 0

@media (min-width: 768px)
  .dropdown-menu
    display: block!important
    position: relative!important
    box-shadow: none!important
    background-color: inherit
</style>
