<template lang="pug">
docs-modal(
  :title="title",
  :data="signature",
  :is-shown="isShown",
  :is-disabled="isFormDisabled",
  :is-submit-button-disabled="loading",
  :error="error",
  @open-file="openFile",
  @submit="submit"
)
  template(#close-button)
    button.btn-close(aria-label="Close", type="button", @click.prevent="$emit('close')")
  template(#button-icon)
    fa-icon.fa-fw.me-1(
      :icon="['far', loading ? 'circle-notch' : 'signature']",
      :class="loading && 'fa-spin'"
    )
    | Поставити підпис
</template>

<script>
import { sendEvent } from "@/utils/ga";
import { getFileExtension } from "@/utils";
import { Uint8ToBase64, dataURLtoFile } from "@/utils/encode";

import { getDocumentAttachments, signDocument } from "@/services/api/docs.api";

import Logger from "@/modules/logger";
import {
  CtxSignFile,
  findServerIndexByIssuerCN,
  getCAServerByIndex,
  getIssuerCNByIndex,
  getJKSPrivateKeysFromFile,
  moduleFullInitialization,
  readJKSPrivateKeyFile,
  readPrivateKeyFile,
  setCA,
} from "@/modules/eusign";

import Modal from "@/components/Modal.vue";

import { mapActions, mapState } from "vuex";

export default {
  props: {
    isShown: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      default: "",
    },
    newDocumentModel: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    "docs-modal": Modal,
  },
  emits: ["close", "update", "set-document-id"],
  data() {
    return {
      title: "Електронний підпис",
      loading: false,
      error: "",
      documentId: this.id,
    };
  },
  computed: {
    ...mapState({ signature: (state) => state.signature }),
    isFormDisabled() {
      return this.loading || this.hasKey();
    },
  },
  methods: {
    ...mapActions("document", ["createDocument"]),
    ...mapActions("signature", [
      "setKey",
      "setKeys",
      "setKeyInfo",
      "setCAServerIssuerCommonName",
      "setCAServerSelectedIndex",
      "setFile",
      "setPassword",
    ]),
    alert(error) {
      Logger.error(error);
      this.error = error?.message ?? error;
      sendEvent("Помилки ЕЦП", this.error);
    },
    hasKey() {
      return !(
        Object.keys(this.signature.keyInfo).length === 0 &&
        this.signature.keyInfo.constructor === Object
      );
    },
    async openFile(file) {
      this.loading = true;
      this.hideErrorMessage();
      this.setKey("");
      this.setKeys([]);

      if (!file) {
        this.setFile({ name: "Оберіть ЕЦП" });
        return;
      }

      this.setFile(file);

      const extension = getFileExtension(this.signature.file.name);

      if (extension === "jks") {
        try {
          const keys = await getJKSPrivateKeysFromFile(this.signature.file);
          this.setKeys(keys);
          this.setKey(keys?.[0]?.alias ?? "");
        } catch (error) {
          this.alert("Ключів не знайдено");
        } finally {
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
    },
    async submit() {
      this.loading = true;
      const extension = getFileExtension(this.signature.file.name);

      await moduleFullInitialization();

      if (extension === "jks") {
        await this.readJKSFile();
      } else if (this.signature.CAServerIssuerCommonName) {
        await this.readFileByIssuerCN();
      } else if (this.signature.CAServerSelectedIndex) {
        await this.readFileByServerIndex();
      } else {
        await this.readFileDefault();
      }
    },
    async readJKSFile() {
      const CAServerIndex = {
        PrivatBank: 6,
      };
      this.setCAServerSelectedIndex(CAServerIndex.PrivatBank);
      this.setCAServerIssuerCommonName(getIssuerCNByIndex(CAServerIndex.PrivatBank));
      await this.readFileJKS();
    },
    async readFileByIssuerCN() {
      const serverIndex = findServerIndexByIssuerCN(this.signature.CAServerIssuerCommonName);
      if (serverIndex > -1) {
        setCA(serverIndex);
        this.setCAServerSelectedIndex(serverIndex + 1);
        await this.readFile(serverIndex);
      } else {
        Logger.error(
          "Сертифікаційний сервер",
          this.signature.CAServerIssuerCommonName,
          "не знайдено"
        );
      }
    },
    async readFileByServerIndex() {
      const server = getCAServerByIndex(this.signature.CAServerSelectedIndex);
      this.setCAServerIssuerCommonName(server.issuerCN);
      setCA(server);
      await this.readFile(server);
    },
    async readFileDefault() {
      this.currentCheckCA = 0;
      this.setCAServerSelectedIndex(0);
      await this.readFile(getCAServerByIndex(0));
    },
    async readFile(CAserver) {
      try {
        const keyInfo = await readPrivateKeyFile(
          this.signature.file,
          this.signature.password,
          CAserver
        );
        this.setKeyInfo(keyInfo);
        await this.onSuccessReadKey();
      } catch (error) {
        this.onErrorReadKey(error);
      }
    },
    async readFileJKS() {
      try {
        const keyInfo = await readJKSPrivateKeyFile(
          this.signature.file,
          this.signature.key,
          this.signature.password
        );
        this.setKeyInfo(keyInfo);
        await this.onSuccessReadKey();
      } catch (error) {
        this.alert(error);
        this.loading = false;
      }
    },
    async onSuccessReadKey() {
      const isValidDocumentId = this.documentId?.length;
      const isValidDocumentCreateModel = Object.keys(this.newDocumentModel).length > 0;
      if (!isValidDocumentId) {
        if (isValidDocumentCreateModel) {
          this.documentId = await this.createDocument(this.newDocumentModel);
          this.$emit("set-document-id", this.documentId);
        } else {
          throw new Error(
            "Ідентифікаційний номер документу не знайдено або дані документа пошкоджено"
          );
        }
      }
      const attachment = await this.fetchAttachment();
      await this.sign(attachment);
    },
    onErrorReadKey(error) {
      try {
        const incorrectPasswordAndCertificateNotFoundErrorCodes = [51, 5];
        if (incorrectPasswordAndCertificateNotFoundErrorCodes.includes(error.errorCode)) {
          this.processExpectedError(error);
        } else {
          this.processDefaultError(error);
        }
      } finally {
        this.loading = false;
      }
    },
    processExpectedError(error) {
      this.currentCheckCA = Number(this.currentCheckCA) + 1;
      const server = getCAServerByIndex(this.currentCheckCA);
      if (server !== undefined) {
        this.setCAServerSelectedIndex(this.currentCheckCA);
        this.readFile(server);
      } else {
        this.setPassword("");
        this.setFile(null);
        this.alert(error);
      }
    },
    processDefaultError(error) {
      this.alert(error);
    },
    async fetchAttachment() {
      const attachment = await getDocumentAttachments(this.documentId);
      if (!attachment.isValid()) {
        throw new Error("Помилка підпису документа. Додаток пошкоджений або відсутній");
      }
      return dataURLtoFile(attachment.file, attachment.filename);
    },
    async sign(attachment) {
      const uint8ArraySignDocument = await CtxSignFile(
        this.signature.file,
        this.signature.key,
        this.signature.password,
        attachment
      );
      await this.signDocumentFromCtx(uint8ArraySignDocument);
    },
    async signDocumentFromCtx(uint8arraySignDocument) {
      try {
        const base64 = Uint8ToBase64(uint8arraySignDocument);
        const signedDocument = await signDocument(this.documentId, {
          file: `data:application/pkcs7-signature;base64,${base64}`,
          serial: this.signature.keyInfo.serial,
          authority: this.signature.keyInfo.issuerCN,
          organization: this.signature.keyInfo.subjOrg,
        });
        this.$emit("update", signedDocument);
        sendEvent("Документ підписано");
        this.$emit("close");
      } catch (error) {
        Logger.error("Помилка при підписанні документу на стороні додатку");
        this.setKeyInfo({});
        throw error;
      }
    },
    hideErrorMessage() {
      this.error = "";
    },
  },
};
</script>
