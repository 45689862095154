export class DocumentListModel {
  /** @type {DocumentModel[]} */
  documents;

  constructor(documents) {
    this.documents = documents;
  }
}

export class DocumentModel {
  /** @type {AttachmentModel} */
  attachment;
  /** @type {String} */
  code;
  /** @type {String} */
  folder;
  /** @type {String} */
  from;
  /** @type {String} */
  id;
  /** @type {Boolean} */
  isRead;
  /** @type {String} */
  message;
  /** @type {Boolean} */
  public;
  /** @type {String} */
  subject;
  /** @type {String} */
  timestamp;

  constructor(doc) {
    this.attachment = new AttachmentModel(doc.attachment);
    this.code = doc.code;
    this.folder = doc.folder;
    this.from = doc.from;
    this.id = doc.id;
    this.isRead = doc.isRead;
    this.message = doc.message;
    this.public = doc.public;
    this.subject = doc.subject;
    this.timestamp = doc.timestamp;
  }
}

class AttachmentModel {
  /** @type {String} */
  name;
  /** @type {String} */
  originalPath;
  /** @type {String} */
  path;
  /** @type {SignatureModel[]} */
  signatures;
  /** @type {String} */
  type;

  constructor({ name, originalPath, path, signatures, type }) {
    this.name = name;
    this.originalPath = originalPath;
    this.path = path;
    this.signatures = signatures.map((sign) => new SignatureModel(sign));
    this.type = type;
  }
}

class SignatureModel {
  /** @type {String} */
  authority;
  /** @type {String} */
  code;
  /** @type {String} */
  date;
  /** @type {String} */
  name;
  /** @type {String} */
  organization;
  /** @type {String} */
  serial;

  constructor({ authority, code, date, name, organization, serial }) {
    this.authority = authority;
    this.code = code;
    this.date = date;
    this.name = name;
    this.organization = organization;
    this.serial = serial;
  }
}

export class FileModel {
  /** @type {String} */
  content;
  /** @type {String} */
  fileName;

  constructor(data) {
    this.content = data.content;
    this.fileName = data.fileName;
  }
}

export class NewDocumentModel {
  /** @type {String} */
  subject;
  /** @type {String} */
  message;
  /** @type {Boolean} */
  isPublic;
  /** @type {FileAttachment} */
  attachment;

  constructor(subject, message, isPublic, attachment) {
    this.subject = subject;
    this.message = message;
    this.public = isPublic;
    this.attachment = attachment;
  }
}

export class FileAttachment {
  /** @type {String} */
  name;
  /** @type {String} - Base64 */
  data;
  /** @type {AttachmentAction[]} */
  actions;

  constructor(name, data, actions) {
    this.name = name;
    this.data = data;
    this.actions = actions;
  }
}

export class AttachmentAction {
  /** @type {String} */
  code;
  /** @type {String} */
  type;
  /** @type {String} */
  handler;

  constructor(code, type, handler) {
    this.code = code;
    this.type = type;
    this.handler = handler;
  }
}

export class Attachment {
  /** @type {String} */
  file;
  /** @type {String} */
  filename;

  constructor(data) {
    this.file = data.file;
    this.filename = data.filename;
  }

  isValid() {
    return this.file && this.filename;
  }
}
