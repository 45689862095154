import {
  createDocument,
  getDocuments,
  markDocumentAsRead,
  markDocumentAsUnread,
  moveDocumentTo,
  removeDocument,
  setDocumentAsPrivate,
  setDocumentAsPublic,
} from "@/services/api/docs.api";
import { DocumentListModel } from "@/models/document";

import RefreshTokenError from "@/models/error";

const Folder = {
  ALL: undefined,
  FAVORITES: "favorites",
  ARCHIVE: "archive",
  ROOT: "/",
};
const folders = [Folder.ALL, Folder.FAVORITES, Folder.ARCHIVE];

const Filter = {
  UNSIGNED: "unsigned",
  SIGNED: "signed",
  INBOUND: "inbound",
  OUTBOUND: "outbound",
  NO_FILTER: undefined,
};
const filters = [Filter.UNSIGNED, Filter.SIGNED, Filter.INBOUND, Filter.OUTBOUND, Filter.NO_FILTER];

const defaultFolderAndFilters = () => ({
  [Filter.UNSIGNED]: [],
  [Filter.SIGNED]: [],
  [Filter.INBOUND]: [],
  [Filter.OUTBOUND]: [],
  [Filter.NO_FILTER]: [],
});

const defaultDocumentsFactory = () => ({
  [Folder.ALL]: defaultFolderAndFilters(),
  [Folder.FAVORITES]: defaultFolderAndFilters(),
  [Folder.ARCHIVE]: defaultFolderAndFilters(),
});

const state = () => ({
  documents: defaultDocumentsFactory(),
  folder: Folder.ALL,
  filter: Filter.NO_FILTER,
  limit: 10,
  lastChunk: defaultDocumentsFactory(),
});

const mutations = {
  SET_FOLDER(state, folder) {
    state.folder = folder;
  },
  SET_FILTER(state, filter) {
    state.filter = filter;
  },
  ADD_DOCUMENTS(state, documents) {
    state.documents[state.folder][state.filter].push(...documents);
  },
  REMOVE_DOCUMENT(state, id) {
    folders.forEach((folder) => {
      filters.forEach((filter) => {
        state.documents[folder][filter] = state.documents[folder][filter].filter(
          (doc) => doc.id !== id
        );
      });
    });
  },
  UPDATE_DOCUMENT(state, { doc, id }) {
    folders.forEach((folder) => {
      filters.forEach((filter) => {
        const docIndex = state.documents[folder][filter].findIndex((_doc) => _doc.id === id);
        if (docIndex > -1) {
          state.documents[folder][filter][docIndex] = doc;
        }
      });
    });
  },
  RESET_DOCUMENTS(state) {
    state.documents = defaultDocumentsFactory();
    state.lastChunk = defaultDocumentsFactory();
  },
  RESET_DOCUMENTS_BY_FOLDER_AND_FILTER(state, { folder, filter }) {
    state.documents[folder][filter] = [];
  },
  SET_LAST_CHUNK(state, chunk) {
    state.lastChunk[state.folder][state.filter] = chunk;
  },
  SET_DOCUMENT_UNREAD(state, id) {
    folders.forEach((folder) => {
      filters.forEach((filter) => {
        const docIndex = state.documents[folder][filter].findIndex((doc) => doc.id === id);
        if (docIndex > -1) {
          state.documents[folder][filter][docIndex].isRead = false;
        }
      });
    });
  },
  SET_DOCUMENT_READ(state, id) {
    folders.forEach((folder) => {
      filters.forEach((filter) => {
        const docIndex = state.documents[folder][filter].findIndex((doc) => doc.id === id);
        if (docIndex > -1) {
          state.documents[folder][filter][docIndex].isRead = true;
        }
      });
    });
  },
  SET_DOCUMENT_FOLDER_FAVORITES(state, id) {
    filters.forEach((filter) => {
      const docIndex = state.documents[Folder.ALL][filter].findIndex((doc) => doc.id === id);
      if (docIndex > -1) {
        const doc = state.documents[Folder.ALL][filter][docIndex];

        doc.folder = Folder.FAVORITES;

        state.documents[Folder.FAVORITES][filter] = [];
      }
    });
  },
  REMOVE_DOCUMENT_FOLDER_FAVORITES(state, id) {
    filters.forEach((filter) => {
      state.documents[Folder.FAVORITES][filter] = state.documents[Folder.FAVORITES][filter].filter(
        (doc) => doc.id !== id
      );
      const docIndex = state.documents[Folder.ALL][filter].findIndex((doc) => doc.id === id);
      if (docIndex > -1) {
        state.documents[Folder.ALL][filter][docIndex].folder = Folder.ALL;
      }
    });
  },
  SET_DOCUMENT_FOLDER_ARCHIVE(state) {
    state.documents = defaultDocumentsFactory();
    state.lastChunk = defaultDocumentsFactory();
  },
  REMOVE_DOCUMENT_FOLDER_ARCHIVE(state) {
    state.documents = defaultDocumentsFactory();
    state.lastChunk = defaultDocumentsFactory();
  },
};

const getters = {
  allDocuments: (state) => {
    return state.documents[state.folder][state.filter];
  },
  getDocumentById: (state) => (id) => {
    return state.documents[state.folder][state.filter].find((doc) => doc.id === id);
  },
  isDocumentListEmpty: (state) => {
    return state.documents[state.folder][state.filter].length === 0;
  },
  noMoreDocumentsLeft: (state) => {
    return state.lastChunk[state.folder][state.filter].length < state.limit;
  },
  getQueryParams: (state) => {
    return {
      folder: state.folder,
      filter: state.filter,
      limit: state.limit,
      offset: state.documents[state.folder][state.filter].length,
    };
  },
};

const actions = {
  setFolder({ commit }, folder) {
    commit("SET_FOLDER", folder);
  },
  setFilter({ commit }, filter) {
    commit("SET_FILTER", filter);
  },
  async fetchDocumentsChunk({ dispatch, getters }) {
    try {
      const chunk = await getDocs(getters.getQueryParams);
      await dispatch("updateDocuments", chunk);
    } catch (error) {
      if (is401FromError(error)) {
        try {
          await dispatch("auth/refreshToken", null, { root: true });
        } catch (error) {
          await dispatch("auth/logout", "authentication", { root: true });
          throw new RefreshTokenError(401, error.message);
        }
        const chunk = await getDocs(getters.getQueryParams);
        await dispatch("updateDocuments", chunk);
      } else if (is404FromError(error)) {
        await dispatch("updateDocuments", await dispatch("getDocumentEmptyList"));
      } else {
        await dispatch("updateDocuments", iosSafariFastReloadingNetworkError());
      }
    }
  },
  async updateDocuments({ commit }, chunk) {
    commit("SET_LAST_CHUNK", chunk);
    commit("ADD_DOCUMENTS", chunk);
  },
  getDocumentEmptyList() {
    return new DocumentListModel([]).documents;
  },
  resetDocuments({ commit }) {
    commit("RESET_DOCUMENTS");
  },
  markDocumentAsUnread({ commit }, id) {
    markDocumentAsUnread(id);
    commit("SET_DOCUMENT_UNREAD", id);
  },
  markDocumentAsRead({ commit }, id) {
    markDocumentAsRead(id);
    commit("SET_DOCUMENT_READ", id);
  },
  async moveDocumentToFavorites({ commit }, id) {
    await moveDocumentTo(id, Folder.FAVORITES);
    commit("SET_DOCUMENT_FOLDER_FAVORITES", id);
  },
  async removeDocumentFromFavorites({ commit }, id) {
    await moveDocumentTo(id, Folder.ROOT);
    commit("REMOVE_DOCUMENT_FOLDER_FAVORITES", id);
  },
  async moveDocumentToArchive({ commit }, id) {
    await moveDocumentTo(id, Folder.ARCHIVE);
    commit("SET_DOCUMENT_FOLDER_ARCHIVE");
  },
  async removeDocumentFromArchive({ commit }, id) {
    await moveDocumentTo(id, Folder.ROOT);
    commit("REMOVE_DOCUMENT_FOLDER_ARCHIVE");
  },
  async removeDocument({ commit }, id) {
    await removeDocument(id);
    commit("REMOVE_DOCUMENT", id);
  },
  async updateDocument({ commit }, doc) {
    commit("UPDATE_DOCUMENT", { doc, id: doc.id });
  },
  async createDocument({ commit }, documentRequestData) {
    const { document: id } = await createDocument(documentRequestData);
    filters.forEach((filter) => {
      commit("RESET_DOCUMENTS_BY_FOLDER_AND_FILTER", { folder: Folder.ALL, filter });
      commit("RESET_DOCUMENTS_BY_FOLDER_AND_FILTER", { folder: Folder.FAVORITES, filter });
    });
    return id;
  },
  async setDocumentPublic(_, id) {
    await setDocumentAsPublic(id);
  },
  async setDocumentPrivate(_, id) {
    await setDocumentAsPrivate(id);
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};

async function getDocs(params) {
  const { data } = await getDocuments(params);
  const { documents } = new DocumentListModel(data.items);
  return documents;
}

function is401FromError(error) {
  return error.response?.data?.code === 401;
}

function is404FromError(error) {
  return error.response?.data?.error?.code === 404;
}

function iosSafariFastReloadingNetworkError() {
  return [];
}
