import http from "@/services/api/http-client";
import * as AuthAPI from "@/services/api/auth.api";

import { JWTTokenModel, UserModel } from "@/models/auth";

import { setCookie, getCookie, deleteCookie } from "@/utils/cookie";

const Token = {
  ACCESS: "accessToken",
  REFRESH: "refreshToken",
};

const ONE_YEAR_IN_MS = 365 * 24 * 60 * 60 * 1000;

const state = () => ({
  accessToken: undefined,
  refreshToken: undefined,
});

const getters = {
  isUserAuthorized(state) {
    return !!state.accessToken;
  },
  refreshToken(state) {
    return state.refreshToken;
  },
};

const actions = {
  async authentication({ state, dispatch }, certificates) {
    try {
      const { data } = await AuthAPI.authenticateUserJWT(certificates);
      dispatch("saveAuthorizationTokens", new JWTTokenModel(data));
      setAuthorizationHeader(state.accessToken);
      await dispatch("authorizeUser");
    } catch (error) {
      dispatch("logout");
      throw new Error("Авторизація не вдалася. Спробуйте оновити сторінку і спробувати ще раз.");
    }
  },
  async logout({ dispatch }, mode) {
    removeAuthorizationHeader();
    await dispatch("user/setAuthorizationFail", null, { root: true });
    await dispatch("document/resetDocuments", null, { root: true });
    await dispatch("user/removeUserData", null, { root: true });
    await dispatch("removeAuthorizationTokens");
    await dispatch("signature/resetSignatureData", mode, { root: true });
  },
  async refreshToken({ state, dispatch }) {
    removeAuthorizationHeader();
    try {
      const { data } = await AuthAPI.refreshToken(state.refreshToken);
      if (data?.error) {
        throw data.error;
      }
      dispatch("saveAuthorizationTokens", new JWTTokenModel(data));
      setAuthorizationHeader(state.accessToken);
    } catch (error) {
      dispatch("logout");
      throw error;
    }
  },
  async authorizeUser({ state, dispatch }) {
    try {
      const response = await AuthAPI.authorizeUser();
      if (is401FromResponse(response)) {
        dispatch("user/setAuthorizationFail", null, { root: true });
      } else {
        dispatch("user/setUser", new UserModel(response.data), { root: true });
        dispatch("user/setAuthorizationSuccess", null, { root: true });
        setAuthorizationHeader(state.accessToken);
      }
    } catch (error) {
      dispatch("logout");
      throw error;
    }
  },
  loadAuthTokenFromCache({ commit, state }) {
    commit("SET_ACCESS_TOKEN", getCookie(Token.ACCESS));
    commit("SET_REFRESH_TOKEN", getCookie(Token.REFRESH));
    setAuthorizationHeader(state.accessToken);
  },
  saveAuthorizationTokens({ commit }, { accessToken, refreshToken }) {
    commit("SET_ACCESS_TOKEN", accessToken);
    setCookie(Token.ACCESS, accessToken, { expires: new Date(Date.now() + ONE_YEAR_IN_MS) });

    commit("SET_REFRESH_TOKEN", refreshToken);
    setCookie(Token.REFRESH, refreshToken, { expires: new Date(Date.now() + ONE_YEAR_IN_MS) });
  },
  removeAuthorizationTokens({ commit }) {
    commit("DELETE_ACCESS_TOKEN");
    deleteCookie(Token.ACCESS);

    commit("DELETE_REFRESH_TOKEN");
    deleteCookie(Token.REFRESH);
  },
};

const mutations = {
  SET_ACCESS_TOKEN(state, token) {
    state.accessToken = token;
  },
  SET_REFRESH_TOKEN(state, token) {
    state.refreshToken = token;
  },
  DELETE_ACCESS_TOKEN(state) {
    delete state.accessToken;
  },
  DELETE_REFRESH_TOKEN(state) {
    delete state.refreshToken;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

function is401FromError(error) {
  return error.response?.data?.code === 401;
}

function is401FromResponse(response) {
  return response.data.error?.code === 401;
}

function setAuthorizationHeader(accessToken) {
  if (accessToken) {
    http.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
  }
}

function removeAuthorizationHeader() {
  delete http.defaults.headers["Authorization"];
}
