<template lang="pug">
.d-flex.flex-md-column.p-2.pe-md-0.ms-auto.border-bottom.remove-border-bottom
  .me-auto
    h1
      router-link.text-dark(to="/docs", :class="[isAndroid ? '' : 'fw-bolder']") {{ headerTitle }}
    .small.text-black-50(
      v-if="filters[$route.query.filter]",
      style="position: relative; bottom: 0.25rem"
    ) {{ filters[$route.query.filter].toUpperCase() }}
    p.mb-0.mb-md-2(:class="!user.isAuthorized && 'placeholder-glow'")
      span.me-1(v-if="user.name") {{ caps(user.name) }}
      span.me-1.placeholder.col-6.col-3-md.col-2-lg(v-else, style="max-width: 20rem")
      span.text-nowrap(v-if="user.code") {{ code(user.code) }}
      span.placeholder.col-4.col-3-md.col-2-lg(v-else, style="max-width: 16rem")
  .me-2
    router-link(to="/new")
      button.btn.btn-success
        fa-icon(:icon="['far', 'file-plus']")
        span.ms-md-2.d-none.d-md-inline Створити документ
  docs-menu.d-md-block(ref="menu", @logout="logout")
</template>

<script>
import { IS_ANDROID } from "@/utils";
import { caps, code } from "@/utils/filters";

import Menu from "@/components/Menu";
import ExpandHorizontal from "@/components/transitions/ExpandHorizontal";

import { mapActions, mapState } from "vuex";
import { logoutUser } from "@/services/api/auth.api";

const Folder = {
  ALL: undefined,
  FAVORITES: "favorites",
  ARCHIVE: "archive",
  NEW: "new",
};

const Filter = {
  SIGNED: "signed",
  UNSIGNED: "unsigned",
  INBOUND: "inbound",
  OUTBOUND: "outbound",
};

export default {
  components: {
    "docs-menu": Menu,
    "transition-expand-horizontal": ExpandHorizontal,
  },
  data() {
    return {
      isAndroid: IS_ANDROID,
      folders: {
        [Folder.ALL]: "Документи",
        [Folder.FAVORITES]: "Улюблені",
        [Folder.ARCHIVE]: "Архів",
        [Folder.NEW]: "Новий документ",
      },
      filters: {
        [Filter.SIGNED]: "Підписані",
        [Filter.UNSIGNED]: "На підпис",
        [Filter.INBOUND]: "Вхідні",
        [Filter.OUTBOUND]: "Вихідні",
      },
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    headerTitle() {
      if (this.$route.name !== "docs") {
        return this.folders[this.$route.name] || "Документи";
      }
      return this.folders[this.$route.query.folder];
    },
  },
  methods: {
    ...mapActions("auth", { logoutUser: "logout" }),
    async logout() {
      const result = await logoutUser();
      if (result.data.success) {
        await this.logoutUser();
        this.$router.push("/");
      }
    },
    caps(value) {
      return caps(value);
    },
    code(value) {
      return code(value);
    },
  },
};
</script>

<style lang="sass" scoped>
@media (min-width: 768px)
  #sidemenu
    max-width: 16rem

  .remove-border-bottom
    border-bottom: none !important
</style>
