<template lang="pug">
section
  docs-signature-form(
    :is-shown="isSignatureFormShown",
    :new-document-model="newDocumentModel",
    @set-document-id="updateDocumentId",
    @close="hideSignatureForm",
    @update="redirectToDocumentPage"
  )
  .container.py-3
    docs-alert(:error="apiErrorMessage")
    .row.justify-content-center
      .col
        h2 Підпис документа
        form(action="/", method="post", v-cloak, @submit.prevent="onSubmit")
          fieldset(:disabled="isLoading")
            .mb-3
              input.form-control(
                type="file",
                @change="updateFilename",
                required,
                ref="fileinput",
                accept=".pdf, .doc, .docx, .odt, .xls, .xlsx, .p7s, .p7m, .txt, .xml, .rtf"
              )
            .mb-3
              input.form-control(
                v-model="document.subject",
                spellcheck="false",
                required,
                placeholder="Назва документа",
                ref="docname"
              )

            .mb-3
              textarea.form-control(
                placeholder="Повідомлення",
                v-model="document.message",
                rows="4",
                maxlength="1024"
              )
            .mb-3
              .input-group
                input.form-control(
                  autocomplete="off",
                  spellcheck="false",
                  type="text",
                  placeholder="Код компаніі або ІПН підпісанта",
                  v-model="code",
                  @focus="toggleSuggestions",
                  @input="hideErrors",
                  @keydown.enter.prevent="fetchCompanyOrPersonInfo",
                  :disabled="loadingSignature",
                  v-only-click-outside="{ exclude: ['dropdown'], handler: 'hideSuggestions' }"
                )
                .input-group-append
                  button.btn.btn-light.border(
                    type="button",
                    @click="fetchCompanyOrPersonInfo",
                    :disabled="!isCodeLengthValid || loadingSignature"
                  )
                    fa-icon(v-if="!loadingSignature", :icon="['fas', 'search']")
                    fa-icon.fa-spin(v-else, :icon="['fas', 'circle-notch']")
              .dropdown(ref="dropdown")
                transition-dropdown
                  .dropdown-menu.w-100.fade.overflow-auto(
                    v-if="showSuggests && storedSignatures.length",
                    style="max-height: 10rem",
                  )
                    a.dropdown-item.text-truncate(
                      href="#",
                      v-for="(signature, idx) in storedSignatures",
                      @click.prevent="chooseSuggestion(idx)",
                      @keydown.enter.prevent="chooseSuggestion(idx)",
                    )
                      span.text-monospace {{ signature.code }}
                      | &nbsp;{{ signature.name }}
              transition-expand
                .invalid-feedback.d-block(v-if="!isCodeCorrect") Невірний код компанії або ІПН
              transition-expand
                .invalid-feedback.d-block(v-if="noSignaturesAdded && isErrorVisible") Оберіть підписанта документу

            transition-group-notifications
              .mb-3(
                v-for="(signature, idx) in signatures",
                :key="signature.code",
                :class="{ shaker: idx === existedSignatureIdx }"
              )
                a.text-danger(
                  :href="isLoading ? undefined : '#'",
                  @click.prevent="removeSignature(idx)",
                  title="Видалити підпис"
                )
                  fa-icon.fa-fw.me-1(:icon="['far', 'minus-circle']")
                span(v-if="isIndividualTaxpayerNumber(signature.code)")
                  span.text-monospace {{ signature.code }}
                a.text-monospace(
                  v-else,
                  :href="getLinkToCompanyPage(signature.code)",
                  target="_blank",
                  title="Відкрити дані в новому вікні"
                ) {{ signature.code }}
                | &nbsp;{{ signature.name }}
            .p-2
            transition-expand
              section(v-show="addMySignature")
                .mb-3
                  fa-icon.fa-fw.me-1(:icon="['far', 'signature']")
                  span.text-monospace {{ user.code }}
                  | &nbsp;{{ user.name }}
            .mb-3
              .form-check.form-switch
                input#add-my-signature.form-check-input(
                  type="checkbox",
                  v-model="addMySignature",
                  checked
                )
                label.custom-control-label(for="add-my-signature") Поставити власний підпис

            .mb-3
              button-spinner.btn.btn-primary(type="submit")
                fa-icon.me-2(v-if="!isLoading", :icon="['far', 'file-plus']")
                | Створити
</template>

<script>
import ButtonSpinner from "@/components/ButtonSpinner";
import Expand from "@/components/transitions/Expand";
import Dropdown from "@/components/transitions/Dropdown";
import Notifications from "@/components/transitions/Notifications";
import Alert from "@/components/Alert";
import SignatureForm from "@/components/SignatureForm";

import { getPersonOrCompanyInfo } from "@/services/api/registry.api";
import { getDocumentAttachments, signDocument } from "@/services/api/docs.api";

import { CtxSignFile } from "@/modules/eusign";
import Logger from "@/modules/logger";

import { UserModel } from "@/models/registry";
import { NewDocumentModel, AttachmentAction, FileAttachment } from "@/models/document";

import { dataURLtoFile, fileToBase64, Uint8ToBase64 } from "@/utils/encode";
import { sendEvent } from "@/utils/ga";

import { mapActions, mapState } from "vuex";

export default {
  components: {
    ButtonSpinner,
    "transition-expand": Expand,
    "transition-dropdown": Dropdown,
    "transition-group-notifications": Notifications,
    "docs-alert": Alert,
    "docs-signature-form": SignatureForm,
  },
  data() {
    return {
      document: {
        subject: "",
        message: undefined,
        isPublic: undefined,
        file: {},
      },
      addMySignature: true,
      code: "",
      docId: "",
      newDocumentModel: {},
      signatures: [],
      storedSignatures: [],
      loadingSignature: false,
      existedSignatureIdx: NaN,
      showSuggests: false,
      isLoading: false,
      isCodeCorrect: true,
      apiErrorMessage: "",
      isErrorVisible: false,
      isSignatureFormShown: false,
      isSignatureRequired: false,
    };
  },
  computed: {
    ...mapState({ signature: (state) => state.signature }),
    ...mapState({ user: (state) => state.user }),
    isCodeLengthValid() {
      const only8or10digitsSurroundedBySpacesOptionally = /^\s*[0-9]{8}\s*$|^\s*[0-9]{10}\s*$/;
      return only8or10digitsSurroundedBySpacesOptionally.test(String(this.code).trim());
    },
    isMySignature() {
      return this.code === this.user.code;
    },
    signaturesToSend() {
      this.hideErrors();
      if (this.addMySignature) {
        return [...this.signatures, this.user];
      }
      return this.signatures;
    },
    noSignaturesAdded() {
      return this.signaturesToSend.length === 0;
    },
  },
  mounted() {
    if (localStorage.getItem("storedSignatures")) {
      try {
        this.storedSignatures = JSON.parse(localStorage.getItem("storedSignatures"));
      } catch (e) {
        localStorage.removeItem("storedSignatures");
      }
    }
  },
  methods: {
    ...mapActions("document", ["createDocument", "removeDocument"]),
    async fetchCompanyOrPersonInfo() {
      if (!this.isCodeLengthValid) return;

      this.hideSuggestions();

      if (this.isMySignature) {
        this.selfSignDocumentModeOn();
        this.code = "";
        return;
      }

      this.code = String(this.code).trim();

      if (!this.isNewSignature(this.code)) return;

      this.loadingSignature = true;

      try {
        const user = new UserModel(await getPersonOrCompanyInfo(this.code));
        this.code = "";
        this.addSignature(user);
        this.addSuggestion(user);
      } catch (error) {
        if (error?.response?.data?.code !== 404) console.error(error);
        this.isCodeCorrect = false;
      } finally {
        this.loadingSignature = false;
      }
    },
    selfSignDocumentModeOn() {
      this.addMySignature = true;
    },
    isNewSignature(code) {
      const showShakingAnimationForItem = (index) => {
        this.existedSignatureIdx = index;
        setTimeout(() => {
          this.existedSignatureIdx = NaN;
        }, 820);
      };

      const index = this.signatures.findIndex((sign) => sign.code === code);
      if (index > -1) {
        showShakingAnimationForItem(index);
        return false;
      }
      return true;
    },
    addSignature(user) {
      this.signatures.unshift(user);
    },
    removeSignature(idx) {
      if (!this.isLoading) {
        this.signatures.splice(idx, 1);
      }
    },
    updateFilename() {
      const file = this.$refs.fileinput.files[0];

      if (!file) return;

      this.document.file = file;
      this.document.subject = file.name;
      this.$refs.docname.focus();
    },
    addSuggestion(item) {
      this.removeSuggestion(item);
      this.storedSignatures.unshift(item);
      this.saveSuggestions();
    },
    removeSuggestion(item) {
      const findIndexByCode = (code) => {
        return this.storedSignatures.findIndex((item) => item.code === code);
      };

      const itemIndex = findIndexByCode(item?.code);

      if (itemIndex > -1) {
        this.storedSignatures.splice(itemIndex, 1);
      }
    },
    saveSuggestions() {
      localStorage.setItem("storedSignatures", JSON.stringify(this.storedSignatures));
    },
    toggleSuggestions() {
      this.showSuggests = !this.showSuggests;
    },
    hideSuggestions() {
      this.showSuggests = false;
    },
    chooseSuggestion(idx) {
      this.code = this.storedSignatures[idx].code;
      this.hideSuggestions();
      this.hideErrors();
      this.fetchCompanyOrPersonInfo();
    },
    async onSubmit() {
      if (!this.isFormValid() || this.loadingSignature) return;

      this.newDocumentModel = new NewDocumentModel(
        this.document.subject,
        this.document.message,
        this.document.isPublic,
        new FileAttachment(
          this.document.file.name,
          await fileToBase64(this.document.file),
          this.signaturesToSend.map((sign) => new AttachmentAction(sign.code, "sign", "//"))
        )
      );

      this.isSignatureRequired = this.addMySignature;

      const isSignatureSet = this.signature.password;
      if (isSignatureSet) {
        try {
          this.isLoading = true;
          this.docId = await this.createDocument(this.newDocumentModel);
          if (this.isSignatureRequired) {
            const attachment = await this.fetchAttachment(this.docId);
            await this.sign(attachment);
          }
          await this.$router.push(`/docs/${this.docId}`);
        } catch (error) {
          if (this.docId) {
            try {
              await this.removeDocument(this.docId);
            } catch {
              // document was not created - skip error
            }
          }
          this.showError(error);
        } finally {
          this.isLoading = false;
        }
      } else {
        if (this.isSignatureRequired) {
          this.showSignatureForm();
        } else {
          this.isLoading = true;
          this.docId = await this.createDocument(this.newDocumentModel);
          await this.$router.push(`/docs/${this.docId}`);
        }
      }
    },
    async sign(attachment) {
      const uint8ArraySignDocument = await CtxSignFile(
        this.signature.file,
        this.signature.key,
        this.signature.password,
        attachment
      );
      await this.signDocumentFromCtx(uint8ArraySignDocument);
    },
    async fetchAttachment(id) {
      const attachment = await getDocumentAttachments(id);
      if (!attachment.isValid()) {
        throw new Error("Помилка підпису документа. Додаток пошкоджений або відсутній");
      }
      return dataURLtoFile(attachment.file, attachment.filename);
    },
    async signDocumentFromCtx(uint8arraySignDocument) {
      const base64 = Uint8ToBase64(uint8arraySignDocument);
      await signDocument(this.docId, {
        file: `data:application/pkcs7-signature;base64,${base64}`,
        serial: this.signature.keyInfo.serial,
        authority: this.signature.keyInfo.issuerCN,
        organization: this.signature.keyInfo.subjOrg,
      });
      sendEvent("Документ підписано");
    },
    updateDocumentId(id) {
      this.docId = id;
    },
    showError(error) {
      this.apiErrorMessage = error?.message || "Виникла помилка при створенні документу";
      Logger.error(error);
    },
    redirectToDocumentPage() {
      this.isLoading = false;
      this.$router.push(`/docs/${this.docId}`);
    },
    showSignatureForm() {
      this.isSignatureFormShown = true;
    },
    hideSignatureForm() {
      this.isSignatureFormShown = false;
    },
    isIndividualTaxpayerNumber(code) {
      return code.length === 10;
    },
    getLinkToCompanyPage(code) {
      return "https://opendatabot.ua/c/" + code;
    },
    hideErrors() {
      this.isCodeCorrect = true;
      this.isErrorVisible = false;
    },
    isFormValid() {
      if (this.noSignaturesAdded) {
        this.isErrorVisible = true;
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
[v-cloak]
  display: none

.fa-spin
  animation-duration: 1s

.shaker
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both
  transform: translate3d(0, 0, 0)
  backface-visibility: hidden
  perspective: 1000px

@keyframes shake
  10%, 90%
    transform: translate3d(-1px, 0, 0)

  20%, 80%
    transform: translate3d(2px, 0, 0)

  30%, 50%, 70%
    transform: translate3d(-4px, 0, 0)

  40%, 60%
    transform: translate3d(4px, 0, 0)
</style>
