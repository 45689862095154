import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faExclamationTriangle,
  faSignature,
  faClock,
  faMinusCircle,
  faCircleNotch,
  faCircle as farCircle,
  faChevronRight,
  faPen,
  faTrash,
  faUndo,
  faEnvelope,
  faEnvelopeOpen,
  faArchive,
  faStar as farStar,
  faFileWord,
  faFilePdf,
  faFileAlt,
  faFile,
  faEllipsisV,
  faCheck,
  faCheckDouble,
  faDotCircle,
  faSignIn,
  faSignOut,
  faArrowAltToBottom,
  faFilePlus,
  faBars,
  faClipboard,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faStar as fasStar,
  faCircle as fasCircle,
  faSearch as fasSearch,
  faCircleNotch as fasCircleNotch,
  faLink as fasLink,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faMobile as fadMobile,
  faHandshake as fadHandshake,
  faSortSizeUp as fadSortSizeUp,
  faFileSignature as fadFileSignature,
  faDatabase as fadDatabase,
  faAbacus as fadAbacus,
} from "@fortawesome/pro-duotone-svg-icons";

const regular = [
  faExclamationTriangle,
  faSignature,
  faClock,
  faCircleNotch,
  farCircle,
  faChevronRight,
  faPen,
  faTrash,
  faUndo,
  faEnvelope,
  faEnvelopeOpen,
  faArrowAltToBottom,
  faArchive,
  farStar,
  faFilePlus,
  faFileWord,
  faFilePdf,
  faFileAlt,
  faFile,
  faEllipsisV,
  faCheck,
  faCheckDouble,
  faMinusCircle,
  faDotCircle,
  faSignIn,
  faSignOut,
  faBars,
  faClipboard,
];
const solid = [fasStar, fasCircle, fasSearch, fasCircleNotch, fasLink];
const duotone = [fadFileSignature, fadHandshake, fadMobile, fadSortSizeUp, fadDatabase, fadAbacus];

library.add(...regular);
library.add(...solid);
library.add(...duotone);

export default FontAwesomeIcon;
