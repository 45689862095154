<template lang="pug">
transition(
  name="dropdown",
  @after-enter="afterEnter",
  @before-enter="beforeEnter",
  @beforeLeave="beforeLeave",
  @afterLeave="afterLeave"
)
  slot
</template>

<script>
export default {
  methods: {
    afterEnter(e) {
      e.classList.toggle("show");
    },
    beforeEnter(e) {
      e.classList.toggle("d-block");
    },
    beforeLeave(e) {
      e.classList.toggle("show");
    },
    afterLeave(e) {
      e.classList.toggle("d-block");
    },
  },
};
</script>
