let handleScroll;
let handleOutsideClick;

export const scroll = {
  beforeMount(el, binding) {
    handleScroll = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", handleScroll);
      }
    };

    window.addEventListener("scroll", handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", handleScroll);
  },
};

export const clickOutside = {
  mounted(el, binding) {
    handleOutsideClick = function (e) {
      e.stopPropagation();
      const { handler, exclude } = binding.value;
      let clickedOnExcludedEl = false;
      exclude.forEach((refName) => {
        if (!clickedOnExcludedEl) {
          const excludedEl = binding.instance.$refs[refName];
          clickedOnExcludedEl = excludedEl?.contains(e.target);
        }
      });

      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        binding.instance[handler]();
      }
    };

    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);
  },
  unmounted() {
    document.removeEventListener("click", handleOutsideClick);
    document.removeEventListener("touchstart", handleOutsideClick);
  },
};

export const onlyClickOutside = {
  mounted(el, binding) {
    handleOutsideClick = function (e) {
      e.stopPropagation();
      const { handler, exclude } = binding.value;
      let clickedOnExcludedEl = false;
      exclude.forEach((refName) => {
        if (!clickedOnExcludedEl) {
          const excludedEl = binding.instance.$refs[refName];
          clickedOnExcludedEl = excludedEl?.contains(e.target);
        }
      });

      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        binding.instance[handler]();
      }
    };

    document.addEventListener("click", handleOutsideClick);
  },
  unmounted() {
    document.removeEventListener("click", handleOutsideClick);
  },
};
