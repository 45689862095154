export const IS_ANDROID = navigator.userAgent.toLowerCase().indexOf("android") > -1;
export const IS_MOBILE = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent
);

export function getFileExtension(filename) {
  return filename.substring(filename.lastIndexOf(".") + 1, filename.length) || filename;
}

const debug = true;

export function log(message) {
  if (message && debug) {
    console.log(
      "%c 908" + " %c Debug ",
      "background: #900; color: #fff; padding: 1px; border-radius: 3px 0 0 3px;",
      "background: #bada55; color: #000; padding: 1px; border-radius: 0 3px 3px 0;",
      message
    );
  }
}
