const state = () => ({
  key: "",
  keys: [],
  keyInfo: {},
  CAServerIssuerCommonName: "",
  CAServerSelectedIndex: "",
  file: {
    name: "Оберіть ЕЦП",
  },
  password: "",
});

const mutations = {
  SET_KEY(state, key) {
    state.key = key;
  },
  SET_KEYS(state, keys) {
    state.keys = keys;
  },
  SET_KEY_INFO(state, keyInfo) {
    state.keyInfo = keyInfo;
  },
  SET_CA_SERVER_ISSUER_COMMON_NAME(state, issuerCN) {
    state.CAServerIssuerCommonName = issuerCN;
  },
  SET_CA_SERVER_SELECTED_INDEX(state, serverIndex) {
    state.CAServerSelectedIndex = serverIndex;
  },
  SET_FILE(state, file) {
    state.file = file;
  },
  SET_PASSWORD(state, password) {
    state.password = password;
  },
};

const actions = {
  setKey({ commit }, key) {
    commit("SET_KEY", key);
  },
  setKeys({ commit }, keys) {
    commit("SET_KEYS", keys);
  },
  setKeyInfo({ commit }, keyInfo) {
    commit("SET_KEY_INFO", keyInfo);
  },
  setCAServerIssuerCommonName({ commit }, issuerCN) {
    commit("SET_CA_SERVER_ISSUER_COMMON_NAME", issuerCN);
  },
  setCAServerSelectedIndex({ commit }, serverIndex) {
    commit("SET_CA_SERVER_SELECTED_INDEX", serverIndex);
  },
  setFile({ commit }, file) {
    commit("SET_FILE", file);
  },
  setPassword({ commit }, password) {
    commit("SET_PASSWORD", password);
  },
  resetSignatureData({ dispatch }, mode) {
    if (mode === "authentication") return;
    dispatch("setFile", {});
    dispatch("setKeyInfo", {});
    dispatch("setKeys", []);
    dispatch("setKey", "");
    dispatch("setPassword", "");
    dispatch("setCAServerIssuerCommonName", "");
    dispatch("setCAServerSelectedIndex", "");
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
