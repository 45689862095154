<template lang="pug">
docs-modal(
  :title="title",
  :data="signature",
  :is-shown="isShown",
  :is-disabled="isFormDisabled",
  :is-submit-button-disabled="isFormDisabled",
  :error="error",
  @open-file="openFile",
  @submit="submit"
)
  template(#close-button, v-if="closeable")
    button.btn-close(aria-label="Close", type="button", @click.prevent="closeAuthorizationForm")
  template(#button-icon)
    fa-icon.fa-fw.me-1(
      :icon="['far', isFormDisabled ? 'circle-notch' : 'sign-in']",
      :class="isFormDisabled && 'fa-spin'"
    )
    | Увійти
</template>

<script>
import Modal from "@/components/Modal";

import { mapState, mapActions } from "vuex";

import { sendEvent } from "@/utils/ga";
import { getFileExtension } from "@/utils";
import { Uint8ToBase64 } from "@/utils/encode";

import {
  findServerIndexByIssuerCN,
  getCAServerByIndex,
  getIssuerCNByIndex,
  getJKSPrivateKeysFromFile,
  getKeyInfoBinaryAndCerificates,
  moduleFullInitialization,
  readJKSPrivateKeyFile,
  readPrivateKeyFile,
  setCA,
} from "@/modules/eusign";

export default {
  props: {
    isShown: {
      type: Boolean,
      required: true,
    },
    closeable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    "docs-modal": Modal,
  },
  emits: ["fetch", "authorized", "close"],
  data() {
    return {
      title: "Аутентифікація",
      isFormDisabled: false,
      error: "",
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    ...mapState({ signature: (state) => state.signature }),
  },
  methods: {
    ...mapActions("auth", ["authentication"]),
    ...mapActions("signature", [
      "setKey",
      "setKeys",
      "setKeyInfo",
      "setCAServerIssuerCommonName",
      "setCAServerSelectedIndex",
      "setFile",
      "setPassword",
    ]),
    alert(error) {
      this.error = error.message ? error.message : error;
      sendEvent("Помилки ЕЦП", this.error);
    },
    closeAuthorizationForm() {
      if (!this.isFormDisabled) {
        this.$emit("close");
      }
    },
    async openFile(file) {
      this.isFormDisabled = true;
      this.hideErrorMessage();
      this.setKey("");
      this.setKeys([]);

      if (!file) {
        this.setFile({ name: "Оберіть ЕЦП" });
        return;
      }

      this.setFile(file);

      const extension = getFileExtension(this.signature.file.name);

      if (extension === "jks") {
        try {
          const keys = await getJKSPrivateKeysFromFile(this.signature.file);
          this.setKeys(keys);
          this.setKey(keys?.[0]?.alias ?? "");
        } catch (error) {
          this.alert("Ключів не знайдено");
        } finally {
          this.isFormDisabled = false;
        }
      } else {
        this.isFormDisabled = false;
      }
    },
    async submit() {
      this.isFormDisabled = true;
      const extension = getFileExtension(this.signature.file.name);

      await moduleFullInitialization();

      if (extension === "jks") {
        await this.readJKSFile();
      } else if (this.signature.CAServerIssuerCommonName) {
        await this.readFileByIssuerCN();
      } else if (this.signature.CAServerSelectedIndex) {
        await this.readFileByServerIndex();
      } else {
        await this.readFileDefault();
      }
    },
    async readJKSFile() {
      const CAServerIndex = {
        PrivatBank: 6,
      };
      this.setCAServerSelectedIndex(CAServerIndex.PrivatBank);
      this.setCAServerIssuerCommonName(getIssuerCNByIndex(CAServerIndex.PrivatBank));
      await this.readFileJKS();
    },
    async readFileByIssuerCN() {
      const serverIndex = findServerIndexByIssuerCN(this.signature.CAServerIssuerCommonName);
      if (serverIndex > -1) {
        setCA(serverIndex);
        this.setCAServerSelectedIndex(serverIndex + 1);
        await this.readFile(serverIndex);
      } else {
        console.error(this.signature.CAServerIssuerCommonName, "was not found");
      }
    },
    async readFileByServerIndex() {
      const server = getCAServerByIndex(this.signature.CAServerSelectedIndex);
      this.setCAServerIssuerCommonName(server.issuerCN);
      setCA(server);
      await this.readFile(server);
    },
    async readFileDefault() {
      this.currentCheckCA = 0;
      this.setCAServerSelectedIndex(0);
      await this.readFile(getCAServerByIndex(0));
    },
    async readFile(CAserver) {
      try {
        const keyInfo = await readPrivateKeyFile(
          this.signature.file,
          this.signature.password,
          CAserver
        );
        this.setKeyInfo(keyInfo);
        await this.onSuccessReadKey();
      } catch (error) {
        this.onErrorReadKey(error);
      }
    },
    async readFileJKS() {
      try {
        const keyInfo = await readJKSPrivateKeyFile(
          this.signature.file,
          this.signature.key,
          this.signature.password
        );
        this.setKeyInfo(keyInfo);
        await this.onSuccessReadKey();
      } catch (error) {
        this.alert(error);
        this.isFormDisabled = false;
      }
    },
    async onSuccessReadKey() {
      try {
        const { certificates } = await getKeyInfoBinaryAndCerificates(
          this.signature.file,
          this.signature.key,
          this.signature.password
        );
        await this.authentication(Uint8ToBase64(certificates));
        this.hideErrorMessage();
        this.$emit("fetch");
        this.$emit("authorized");
      } catch (error) {
        console.error(error);
        this.alert(error);
      } finally {
        this.isFormDisabled = false;
      }
    },
    onErrorReadKey(error) {
      const incorrectPasswordAndCertificateNotFoundErrorCodes = [51, 5];
      if (incorrectPasswordAndCertificateNotFoundErrorCodes.includes(error.errorCode)) {
        this.processExpectedError(error);
      } else {
        this.processDefaultError(error);
      }
      this.isFormDisabled = false;
    },
    processExpectedError(error) {
      this.currentCheckCA = Number(this.currentCheckCA) + 1;
      const server = getCAServerByIndex(this.currentCheckCA);
      if (server !== undefined) {
        this.setCAServerSelectedIndex(this.currentCheckCA);
        this.readFile(server);
      } else {
        this.setPassword("");
        this.setFile(null);
        this.alert(error);
      }
    },
    processDefaultError(error) {
      this.alert(error);
    },
    hideErrorMessage() {
      this.error = "";
    },
  },
};
</script>
