import { setCookie, getCookie, deleteCookie } from "@/utils/cookie";

const User = {
  NAME: "name",
  CODE: "code",
};

const ONE_YEAR_IN_MS = 365 * 24 * 60 * 60 * 1000;

const state = () => ({
  name: "",
  code: "",
  isAuthorized: false,
});

const mutations = {
  SET_NAME(state, name) {
    state.name = name;
  },
  SET_CODE(state, code) {
    state.code = code;
  },
  SET_IS_AUTHORIZED(state, isAuthorized) {
    state.isAuthorized = isAuthorized;
  },
};

const actions = {
  async loadUserDataFromCache({ commit, state, dispatch }) {
    commit("SET_NAME", decodeURIComponent(getCookie(User.NAME) ?? ""));
    commit("SET_CODE", getCookie(User.CODE) ?? "");
    if (!state.name || !state.code) {
      await dispatch("auth/authorizeUser", null, { root: true });
    }
  },
  setUser({ dispatch }, user) {
    dispatch("setName", user.name);
    dispatch("setCode", user.code);
  },
  setName({ commit }, name) {
    commit("SET_NAME", name);
    setCookie(User.NAME, name, { expires: new Date(Date.now() + ONE_YEAR_IN_MS) });
  },
  setCode({ commit }, code) {
    commit("SET_CODE", code);
    setCookie(User.CODE, code, { expires: new Date(Date.now() + ONE_YEAR_IN_MS) });
  },
  setAuthorizationSuccess({ commit }) {
    commit("SET_IS_AUTHORIZED", true);
  },
  setAuthorizationFail({ commit }) {
    commit("SET_IS_AUTHORIZED", false);
  },
  removeUserData({ dispatch }) {
    dispatch("removeName");
    dispatch("removeCode");
  },
  removeName({ commit }) {
    commit("SET_NAME", "");
    deleteCookie(User.NAME);
  },
  removeCode({ commit }) {
    commit("SET_CODE", "");
    deleteCookie(User.CODE);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
