import axios from "axios";

export const docsApiBaseUrl = process.env.VUE_APP_DOCS_API_BASE_URL;

const httpClientDocs = axios.create({
  baseURL: process.env.VUE_APP_DOCS_API_BASE_URL,
  timeout: 30000,
});

export const httpClientOpendatabot = axios.create({
  baseURL: process.env.VUE_APP_ODB_API_BASE_URL,
  timeout: 30000,
});

export default httpClientDocs;
