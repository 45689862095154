<template lang="pug">
section.container.p-4
  h1(:class="[isAndroid ? '' : 'font-weight-bolder']") 404
  p Документ не знайдено,
    router-link(to="/") перейти на головну сторінку.
  .absolute-center.zoom
    .rotation.position-absolute(v-for="index in 3")
      img.img-fluid(
        :style="{ '--i': index, '--total': 3 }",
        :src="`https://s3-us-west-2.amazonaws.com/s.cdpn.io/1959313/spacesuit${index}.png`",
        draggable="false"
      )
</template>

<script>
import { IS_ANDROID } from "@/utils";

export default {
  data() {
    return {
      isAndroid: IS_ANDROID,
    };
  },
};
</script>
