import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as D from "./utils/directives";
import FontAwesomeIcon from "./modules/fontawesome";

const app = createApp(App);
app.use(store);
app.use(router);
app.component("fa-icon", FontAwesomeIcon);

app.directive("scroll", D.scroll);
app.directive("click-outside", D.clickOutside);
app.directive("only-click-outside", D.onlyClickOutside);

app.mount("#app");

app.config.performance = process.env.NODE_ENV !== "production";
