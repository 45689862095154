export function sendPageview(page) {
  window.ga("set", "page", page);
  window.ga("send", "pageview");
}

export function sendEvent(category, action, label) {
  window.ga("send", "event", category, action, label);
}

export function onErrorEvent(e) {
  if (e.message !== "Script error.") {
    window.ga("send", "exception", {
      exDescription: `${e.message} at ${e.filename}:  ${e.lineno}`,
      exFatal: false,
    });
  }
}
