export class UserModel {
  /** @type { String } */
  code;
  /** @type { String } */
  name;

  constructor(user) {
    this.code = user.code;
    this.name = user.name;
  }
}
