<template lang="pug">
section
  docs-signature-form(
    v-if="doc.id?.length",
    :id="doc.id ?? ''",
    :is-shown="isSignatureFormShown",
    @close="closeSignatureForm",
    @update="update"
  )
  docs-not-found-404(v-if="isFetchDocumentError")
  .modal-zoom.bg-white(v-else, :class="{ 'modal-zoom-out': zoomOut && isMobile }")
    .container-fluid(v-if="isDocumentLoading")
      docs-document-placeholder
    template(v-else)
      .row.px-2
        .col-12.py-3
          .d-flex
            .status-icon(:class="{ show: !doc.isRead }")
              fa-icon.svg-font-awesome.me-2.my-1.text-primary(:icon="['fas', 'circle']")
            .status-icon(:class="{ show: doc.public }")
              fa-icon.svg-font-awesome.me-2.my-1.text-primary(:icon="['fas', 'link']")
            .text-truncate(v-if="user.code === doc.code")
              template(v-for="(signature, index) in doc.attachment.signatures")
                span.fw-bold {{ org(signature.organization) }} {{ signature.name }}
                span(v-if="hasNextSignature(index)") ,
            div(v-else)
              span.fw-bold.text-truncate {{ doc.from }}
            .ms-auto.text-muted.small.my-1.text-nowrap.ps-1
              | {{ calendar(doc.timestamp) }}

          .d-flex
            div
              .status-icon(:class="{ show: doc.folder === 'favorites' }")
                fa-icon.svg-font-awesome.my-1.me-2.text-warning(:icon="['fas', 'star']")
              span {{ doc.subject }}
            .ms-auto
              fa-icon.ms-2(v-if="signatures.isAction", :icon="['far', 'pen']")
              fa-icon.ms-2.text-success(
                v-if="signatures.isSigned",
                :icon="['far', 'check-double']"
              )
        .col-12.overflow-hidden
          p(v-if="doc.message") {{ doc.message }}
          .small.text-muted Створено {{ timestamp(doc.timestamp) }}
          span.text-monospace {{ doc.code }}
          |
          | {{ caps(doc.from) }}

          .p-2

          section(v-if="doc.attachment")
            docs-download-dropdown(
              :files="files",
              :is-opened="isDropdownOpened",
              :is-disabled="files.length === 0",
              @download-file="downloadFile",
              @toggle-download-dropdown="toggleDownloadDropdown",
              @close-download-dropdown="closeDownloadDropdown"
            )

            .p-2

            section(v-if="signatures")
              section(v-if="signatures.signed && signatures.signed.length")
                .small.text-muted Підписано

                section(v-for="signature in signatures.signed")
                  fa-icon.fa-fw.me-2(:icon="['far', signature.date ? 'signature' : 'clock']")
                  span.text-monospace(v-if="isOrganization(signature.organization)")
                    a.text-decoration-none(
                      :href="getLinkToOrganizationPage(signature.code)",
                      target="_blank"
                    )
                      | {{ signature.code }}
                  span.text-monospace(v-else) {{ signature.code }}
                  |
                  | {{ caps(signature.name) }}
                  .small {{ timestamp(signature.date) }}

                  .p-1

              section(v-if="signatures.unsigned && signatures.unsigned.length")
                .small.text-muted Очікуємо підписи

                section(v-for="signature in signatures.unsigned")
                  fa-icon.fa-fw.me-2(:icon="['far', signature.date ? 'signature' : 'clock']")
                  span.text-monospace {{ signature.code }}
                  |
                  | {{ caps(signature.name) }}

                  .p-1

              section(v-if="signatures.isSigned")
                .p-2
                .form-check.form-switch
                  input#toggle-public.form-check-input(
                    type="checkbox",
                    :disabled="isLoading",
                    v-model="doc.public",
                    @click.prevent="togglePublic"
                  )
                  label.custom-control-label(for="toggle-public") Зробити документ публічним
                .p-2

              transition-expand
                section.overflow-hidden(v-show="doc.public")
                  .text-center
                    a(:href="publicFileLink", target="_blank")
                      img.border.rounded.bg-white.p-2(
                        :src="publicFileQRImage",
                        alt="Відскануйте qr-код, щоб отримати посилання",
                        width="150",
                        height="150"
                      )

                  transition-expand
                    section(v-show="isLinkCopied")
                      .text-center
                        span.badge.rounded-pill.bg-dark Посилання скопійовано

                  .p-1
                  .position-relative.text-center
                    input.position-absolute.d-none(
                      type="text",
                      :value="publicFileLink",
                      ref="link"
                    )
                    button.btn.position-relative(@click="copyToClipboard")
                      fa-icon.me-2(:icon="['far', 'clipboard']")
                      | Копіювати посилання

                  .p-1

              section(v-if="signatures.self && signatures.self.length")
                .small.text-muted Ваш підпис

                section(v-for="signature in signatures.self")
                  fa-icon.fa-fw.me-2(:icon="['far', signature.date ? 'signature' : 'clock']")
                  span.text-monospace {{ signature.code }}
                  |
                  | {{ caps(signature.name) }}

                  .p-1

                  button.btn.btn-primary(
                    v-if="user.code === signature.code",
                    @click.prevent="showSignatureForm"
                  )
                    fa-icon.fa-fw.me-2(
                      :icon="['far', signing ? 'cricle-notch' : 'pen']",
                      :class="{ 'fa-spin': signing }"
                    )
                    | Підписати

                  .p-1

      .row.px-2
        .d-flex.flex-nowrap.flex-md-wrap.overflow-auto.border-top.py-1(
          v-if="doc.folder === 'archive'",
          style="position: relative; left: -0.25rem",
          hide-scroll
        )
          button.btn.btn-danger.text-nowrap.m-1(@click.prevent="remove")
            fa-icon.me-1(:icon="['far', 'trash']")
            | Видалити

          .p-1

          button.btn.btn-light.text-nowrap.m-1(@click.prevent="restore")
            fa-icon.me-1(:icon="['far', 'undo']")
            | Воскресити
        .d-flex.flex-nowrap.flex-md-wrap.overflow-auto.border-top.py-1(
          v-else,
          style="position: relative; left: -0.25rem",
          hide-scroll
        )
          button.btn.btn-light.text-nowrap.m-1(@click.prevent="toggleFavorites")
            fa-icon.me-2(:icon="[doc.folder === 'favorites' ? 'fas' : 'far', 'star']")
            | У обране
          button.btn.btn-light.text-nowrap.m-1(@click.prevent="unread")
            fa-icon.me-2(:icon="['far', 'envelope']")
            | Непрочитане
          button.btn.btn-light.text-nowrap.m-1(@click.prevent="archive")
            fa-icon.me-2(:icon="['far', 'archive']")
            | До архіву
</template>

<script>
import JsFileDownload from "js-file-download";

import { calendar, timestamp, caps, org } from "@/utils/filters";
import { sendEvent } from "@/utils/ga";
import { IS_MOBILE } from "@/utils";

import { downloadDocument, getDocument, getLinkToOriginalFile } from "@/services/api/docs.api";

import { FileModel, DocumentModel } from "@/models/document";

import Slide from "@/components/transitions/Slide";
import Expand from "@/components/transitions/Expand.vue";
import DownloadDropdown from "@/components/DownloadDropdown";
import SignatureForm from "@/components/SignatureForm";
import NotFound from "@/components/Lost";
import DocumentPlaceholder from "@/components/DocumentPlaceholder";

import { mapActions, mapGetters, mapState } from "vuex";

const Folder = {
  ARCHIVE: "archive",
  FAVORITES: "favorites",
  ROOT: "/",
};

export default {
  components: {
    "transition-slide": Slide,
    "transition-expand": Expand,
    "docs-download-dropdown": DownloadDropdown,
    "docs-signature-form": SignatureForm,
    "docs-not-found-404": NotFound,
    "docs-document-placeholder": DocumentPlaceholder,
  },
  data() {
    return {
      doc: {},
      signing: false,
      isSignatureFormShown: false,
      isDropdownOpened: false,
      isFetchDocumentError: false,
      isDocumentLoading: true,
      isMobile: IS_MOBILE,
      isLoading: false,
      isLinkCopied: false,
      zoomOut: false,
      icons: {
        doc: "file-word",
        pdf: "file-pdf",
        txt: "file-alt",
        rtf: "file-alt",
      },
    };
  },
  watch: {
    doc() {
      this.isDocumentLoading = false;
    },
  },
  computed: {
    ...mapGetters("document", ["getDocumentById"]),
    ...mapState({ user: (state) => state.user }),
    files() {
      const files = this.doc?.attachment;
      return [
        {
          name: "Підписаний документ",
          path: files.path,
          icon: "file",
        },
        {
          name: "Оригінальний документ",
          path: files.originalPath,
          icon: this.icons[this.doc.attachment.type] ?? "file",
        },
      ].filter((attachment) => attachment.path?.length);
    },
    publicFileLink() {
      return getLinkToOriginalFile(this.doc.id);
    },
    publicFileQRImage() {
      return `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${this.publicFileLink}`;
    },
    signatures() {
      const signatures = this.doc?.attachment?.signatures ?? [];
      return {
        self: signatures.filter((item) => !item.date && item.code === this.user.code),
        signed: signatures.filter((item) => item.date),
        unsigned: signatures.filter((item) => item.code != this.user.code && !item.date),
        isSigned: !signatures.filter((item) => !item.date).length,
        isAction: !!signatures.filter((item) => !item.date && item.code === this.user.code).length,
      };
    },
  },
  async created() {
    try {
      const documentFromStore = this.getDocumentById(this.$route.params.docId);
      if (documentFromStore) {
        this.doc = documentFromStore;
      } else {
        this.doc = new DocumentModel(await getDocument(this.$route.params.docId));
      }
      this.read();
    } catch (error) {
      console.error(error);
      this.isFetchDocumentError = true;
    }
  },
  methods: {
    ...mapActions("document", [
      "markDocumentAsUnread",
      "markDocumentAsRead",
      "moveDocumentToFavorites",
      "removeDocumentFromFavorites",
      "moveDocumentToArchive",
      "removeDocumentFromArchive",
      "removeDocument",
      "updateDocument",
      "setDocumentPrivate",
      "setDocumentPublic",
    ]),
    copyToClipboard() {
      let clipboard = this.$refs.link;

      clipboard.classList.remove("d-none");
      clipboard.focus();
      clipboard.select();

      try {
        document.execCommand("copy");

        this.isLinkCopied = true;

        clipboard.classList.add("d-none");
        clipboard.blur();

        setTimeout(() => {
          this.isLinkCopied = false;
        }, 2000);
      } catch (err) {
        console.log("Oops, unable to copy");
      }
    },
    showSignatureForm() {
      this.moveZoomOut();
      this.isSignatureFormShown = true;
    },
    closeSignatureForm() {
      this.moveZoomIn();
      this.isSignatureFormShown = false;
    },
    moveZoomOut() {
      this.zoomOut = true;
    },
    moveZoomIn() {
      this.zoomOut = false;
    },
    update(doc) {
      this.doc = new DocumentModel(doc);
      this.updateDocument(doc);
    },
    hasNextSignature(index) {
      return this.doc.attachment.signatures.length > index + 1;
    },
    isOrganization(type) {
      return type === "org";
    },
    getLinkToOrganizationPage(code) {
      return "https://opendatabot.ua/c/" + code;
    },
    async downloadFile(path) {
      const { data } = await downloadDocument(path);
      const file = new FileModel(data);
      const base64Response = await fetch(file.content);
      const blob = await base64Response.blob();
      JsFileDownload(blob, file.fileName);
    },
    toggleDownloadDropdown() {
      this.isDropdownOpened = !this.isDropdownOpened;
    },
    closeDownloadDropdown() {
      this.isDropdownOpened = false;
    },
    async archive() {
      await this.moveDocumentToArchive(this.doc.id);
      sendEvent("Переміщення в папку", Folder.ARCHIVE);
      await this.$router.push("/docs");
    },
    async restore() {
      await this.removeDocumentFromArchive(this.doc.id);
      sendEvent("Переміщення в папку", Folder.ROOT);
      await this.$router.push("/docs");
    },
    async remove() {
      if (confirm("Ви дійсно бажаєте видалити документ?")) {
        await this.removeDocument(this.doc.id);
        await this.$router.push("/docs");
      }
    },
    read() {
      if (!this.doc.isRead) {
        this.markDocumentAsRead(this.doc.id);
        this.doc.isRead = true;
      }
    },
    unread() {
      if (this.doc.isRead) {
        this.markDocumentAsUnread(this.doc.id);
        this.doc.isRead = false;
      }
    },
    toggleFavorites() {
      const isFolderFavorites = this.doc.folder === Folder.FAVORITES;
      this.doc.folder = isFolderFavorites ? Folder.ROOT : Folder.FAVORITES;
      if (this.doc.folder === Folder.FAVORITES) {
        this.moveDocumentToFavorites(this.doc.id);
      } else {
        this.removeDocumentFromFavorites(this.doc.id);
      }
      sendEvent("Переміщення в папку", this.doc.folder);
    },
    async togglePublic() {
      this.isLoading = true;
      try {
        if (this.doc.public) {
          await this.setDocumentPrivate(this.doc.id);
        } else {
          await this.setDocumentPublic(this.doc.id);
        }
        this.doc.public = !this.doc.public;
      } catch (error) {
        console.error("Помилка при зміні статусу публічності документа");
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    calendar(value) {
      return calendar(value);
    },
    timestamp(value) {
      return timestamp(value);
    },
    caps(value) {
      return caps(value);
    },
    org(value) {
      return org(value);
    },
  },
};
</script>

<style lang="sass" scoped>
[hide-scroll]
  -webkit-overflow-scrolling: touch
  -ms-overflow-style: none
  scrollbar-width: none

  &::-webkit-scrollbar
    display: none

.status-icon
  position: relative

  .svg-font-awesome
    position: absolute
    opacity: 0
    transition: 100ms ease-out
    transform: scale(0.5)

  &.show + &.show
    .svg-font-awesome
      opacity: 1
      left: 1.5rem
      transform: scale(1)

    + span
      padding-left: 3rem


  &.show
    .svg-font-awesome
      opacity: 1
      left: 0
      transform: scale(1)

    + span
      padding-left: 1.5rem

    + div
      padding-left: 1.5rem

  + span
    padding-left: 0px
    transition: all 100ms ease-out

  + div
    padding-left: 0px
    transition: all 100ms ease-out
</style>
