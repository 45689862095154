<template lang="pug">
transition(
  @enter="startFading",
  @after-enter="endFading",
  @before-leave="endFading",
  @after-leave="startFading"
)
  slot
.modal-backdrop.fade(v-show="isShown", ref="backdrop", style="background-color: #f8f9fa")
</template>

<script>
export default {
  props: {
    isShown: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    startFading(e) {
      e.classList.toggle("d-block");
    },
    endFading(e) {
      e.classList.toggle("show");
      this.$refs.backdrop.classList.toggle("show");
    },
  },
};
</script>

<style lang="sass" scoped>
.fade-enter-from,
.fade-leave-to
  opacity: 0

.fade-enter-to,
.fade-leave-from
  opacity: 1

.fade-enter-active,
.fade-leave-active
  transition: opacity 100ms
</style>
