<template lang="pug">
.container-fluid
  .row
    .col-12.col-md-4.p-0(v-if="!['index', '404'].includes($route.name)")
      docs-header#sidemenu
    .col-12.col-md.p-0.overflow-hidden
      docs-alert(:error="error")
      router-view(v-slot="{ Component, route }")
        transition-slide(:direction="direction", mode="out-in")
          component(:is="Component", :key="route.fullPath")
</template>

<script>
import Slide from "@/components/transitions/Slide";
import Alert from "@/components/Alert";
import Header from "@/components/layout/Header.vue";

import { sendPageview, onErrorEvent } from "@/utils/ga";

import { initializeCryptoLibrary } from "@/modules/eusign";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    "docs-header": Header,
    "transition-slide": Slide,
    "docs-alert": Alert,
  },
  data() {
    return {
      error: "",
      direction: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["isUserAuthorized"]),
  },
  watch: {
    $route(to, from) {
      const Pages = {
        INDEX: "index",
        DOCS: "docs",
        DOCUMENT: "document",
        NEW: "new",
      };

      if (isFirstLoad(to, from)) {
        noTransition();
      } else if (to.name === Pages.DOCS && to.name === from.name) {
        this.direction = folderTransitionDirection(to, from);
      } else if (to.name === Pages.DOCUMENT) {
        this.direction = "left";
      } else {
        this.direction = from?.meta?.order < to?.meta?.order ? "left" : "right";
      }

      function folderTransitionDirection(to, from) {
        const order = {
          [undefined]: 0,
          favorites: 1,
          archive: 2,
        };
        return order[to.query.folder] > order[from.query.folder] ? "left" : "right";
      }

      function isFirstLoad(to, from) {
        if (!(from && from.name)) {
          return true;
        }
        return false;
      }

      function noTransition() {
        return;
      }
    },
  },
  created() {
    this.loadAuthTokenFromCache();
    this.loadUserDataFromCache();
  },
  mounted() {
    sendPageview(this.$route.path);

    this.$router.afterEach((to) => {
      const DEFAULT_TITLE = "Документообіг";
      document.title = `${to.meta.title} - ${DEFAULT_TITLE}` || DEFAULT_TITLE;
      sendPageview(to.path);
    });

    initializeCryptoLibrary().catch((error) => {
      this.alert(error.message);
    });

    window.addEventListener("error", onErrorEvent);
  },
  methods: {
    ...mapActions("auth", ["loadAuthTokenFromCache"]),
    ...mapActions("user", ["loadUserDataFromCache"]),
    alert(error) {
      this.error = error && error.message ? error.message : "";
      if (error.errors) {
        this.error += ": ";
        this.error += error.errors.map((err) => err.toLowerCase()).join(", ");
        this.error += ".";
      }
    },
  },
};
</script>

<style lang="sass">
@import "./assets/styles/main.sass"
</style>
