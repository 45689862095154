<template lang="pug">
section
  transition-fade(:is-shown="isShown")
    .modal.fade(v-if="isShown")
      .modal-dialog
        .modal-content.border-0.shadow
          .modal-header
            h5.modal-title {{ title }}
            slot(name="close-button")
          .modal-body
            transition-slide
              section(v-if="error")
                .alert.alert-warning {{ error }}
            form(action="/", method="post", @submit.prevent="submitForm")
              fieldset(:disabled="isDisabled")
                .form-group.mb-3
                  label(for="fileInput") Оберіть ЕЦП
                  input.form-control(
                    type="file",
                    name="fileInput",
                    @change="openFile",
                    ref="file",
                    required
                  )
                transition-slide
                  section(v-if="data.keys.length")
                    .form-group.m-0
                      select.form-select(required, v-model="data.key")
                        option(v-for="(key, index) in data.keys", :value="key.alias") {{ key.name }}
                      .p-2
                .form-group
                  template(v-if="data.keys.length")
                    input.form-control.d-none(
                      v-model="data.key",
                      type="text",
                      autocomplete="username",
                      spellcheck="false",
                      aria-label="Username",
                      name="Username"
                    )
                  template(v-else)
                    input.form-control.d-none(
                      v-model="data.file.name",
                      type="text",
                      autocomplete="username",
                      spellcheck="false",
                      aria-label="Username",
                      name="Username"
                    )
                  input.mb-3.form-control(
                    v-model="data.password",
                    required,
                    placeholder="Пароль",
                    type="password",
                    autocomplete="current-password",
                    spellcheck="false",
                    tabindex="0",
                    aria-label="Confirm",
                    name="ConfirmPasswd"
                  )
              .form-group
                button.btn.btn-primary(type="submit", :disabled="isSubmitButtonDisabled")
                  slot(name="button-icon")
</template>

<script>
import Fade from "@/components/transitions/Fade";
import Slide from "@/components/transitions/Slide";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    isShown: {
      type: Boolean,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
    isSubmitButtonDisabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
  },
  components: {
    "transition-slide": Slide,
    "transition-fade": Fade,
  },
  emits: ["submit", "open-file"],
  methods: {
    submitForm() {
      this.$emit("submit");
    },
    openFile() {
      const file = this.$refs.file.files[0];
      this.$emit("open-file", file);
    },
  },
};
</script>
