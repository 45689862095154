import dayjs from "dayjs";
import "dayjs/locale/uk";
dayjs.locale("uk");

import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);

import localeData from "dayjs/plugin/localeData";
dayjs.extend(localeData);

import calendar from "dayjs/plugin/calendar";
dayjs.extend(calendar);

import updateLocale from "dayjs/plugin/updateLocale";
dayjs.extend(updateLocale);

dayjs.updateLocale("uk", {
  calendar: {
    sameDay: "[Сьогодні о] LT",
    lastDay: "[Вчора о] LT",
    sameElse: "L",
  },
});

export default dayjs;
