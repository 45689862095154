import { createStore, createLogger } from "vuex";

import auth from "./modules/auth";
import signature from "./modules/signature";
import user from "./modules/user";
import document from "./modules/document";

const DEBUG = process.env.NODE_ENV !== "production";

export default createStore({
  plugins: DEBUG ? [createLogger()] : [],
  modules: {
    auth,
    signature,
    user,
    document,
  },
});
