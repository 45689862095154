<template lang="pug">
.row.border-bottom
  .col-12.py-3.position-relative.bg-white
    .d-flex
      router-link.stretched-link.nav-link.text-dark.text-truncate.p-0(:to="/docs/ + doc.id")
        .status-icon(:class="{ show: !doc.isRead }")
          fa-icon.svg-font-awesome.me-2.my-1.fa-fw.text-primary(:icon="['fas', 'circle']")
        .status-icon(v-if="doc.public", :class="{ show: doc.public }")
          fa-icon.svg-font-awesome.me-2.my-1.text-primary(:icon="['fas', 'link']")
        template(v-if="user.code === doc.code")
          span.fw-bold(v-for="(signature, index) in doc.attachment.signatures")
            | {{ org(signature.organization) }} {{ signature.name }}
            span(v-if="doc.attachment.signatures.length > index + 1") ,
        template(v-else)
          span.fw-bold {{ doc.from }}
      .ms-auto.text-muted.small.my-1.text-nowrap.ps-1
        | {{ calendar(doc.timestamp) }}
        fa-icon.fa-fw.ms-1(:icon="['far', 'chevron-right']")

    .d-flex
      .text-truncate
        .status-icon(:class="{ show: doc.folder === 'favorites' }")
          fa-icon.svg-font-awesome.my-1.me-2.text-warning(:icon="['fas', 'star']")
        span {{ doc.subject }}
      .ms-auto
        fa-icon.ms-2(v-if="signatures.isAction", :icon="['far', 'pen']")
        fa-icon.ms-2.text-success(v-if="signatures.isSigned", :icon="['far', 'check-double']")
</template>

<script>
import { calendar, timestamp, org } from "@/utils/filters";

import Slide from "@/components/transitions/Slide";

import { mapState } from "vuex";

export default {
  props: {
    doc: {
      type: Object,
      required: true,
    },
  },
  components: {
    "transition-slide": Slide,
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    signatures() {
      const { signatures } = this.doc.attachment;
      return {
        self: signatures.filter((item) => !item.date && item.code === this.user.code),
        signed: signatures.filter((item) => item.date),
        unsigned: signatures.filter((item) => item.code != this.user.code && !item.date),
        isSigned: !signatures.filter((item) => !item.date).length,
        isAction: !!signatures.filter((item) => !item.date && item.code === this.user.code).length,
      };
    },
  },
  methods: {
    calendar(value) {
      return calendar(value);
    },
    timestamp(value) {
      return timestamp(value);
    },
    org(value) {
      return org(value);
    },
  },
};
</script>

<style lang="sass" scoped>
.status-icon
  position: relative

  & .svg-font-awesome
    position: absolute
    opacity: 0
    transition: 100ms ease-out
    transform: scale(0.5)

  &.show + &.show
    .svg-font-awesome
      opacity: 1
      left: 1.5rem
      transform: scale(1)

    + span
      padding-left: 3rem

  &.show
    .svg-font-awesome
      opacity: 1
      left: 0
      transform: scale(1)

    + span
      padding-left: 1.5rem

  + span
    padding-left: 0px
    transition: all 100ms ease-out
</style>
