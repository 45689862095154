<template lang="pug">
transition(name="expand", @enter="enter", @leave="leave")
  slot
</template>

<script>
export default {
  methods: {
    enter(el) {
      el.style.position = "absolute";
      el.style.visibility = "hidden";
      el.style.width = "auto";

      const { width } = getComputedStyle(el);

      el.style.position = null;
      el.style.visibility = null;
      el.style.width = 0;

      requestAnimationFrame(() => {
        el.style.width = width;
      });
    },
    leave(el) {
      requestAnimationFrame(() => {
        el.style.width = 0;
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.expand-enter-active, .expand-leave-active
  transition: width 250ms ease-in-out
  transition-property: opacity, width

.expand-enter-from, .expand-leave-to
  width: 0
  opacity: 0
</style>
