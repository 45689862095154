<template lang="pug">
.row.border-bottom(:class="{ 'placeholder-glow': loading }")
  .col.pt-3.pb-2
    .d-flex.flex-column
      .d-flex.justify-content-between
        p.placeholder(
          :class="`col-${width + 3} col-sm-${width + 2} col-md-${width + 1} col-lg-${width}`"
        )
        p.placeholder.col-2
      .d-flex.justify-content-between
        p.placeholder.placeholder-sm(
          :class="`col-${width + 1} col-sm-${width} col-md-${width - 1} col-lg-${width - 2}`"
        )
        p.placeholder.placeholder-sm(style="width: 0.75em")
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      required: true,
    },
  },
};
</script>
