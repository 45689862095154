import { createRouter, createWebHistory } from "vue-router";

import Lost from "@/components/Lost";

import IndexPage from "@/pages/index";
import DocumentListPage from "@/pages/docs";
import DocumentPage from "@/pages/document";
import NewDocumentPage from "@/pages/new-document";

import { auth, isLoggedIn } from "./guards";

const Page = {
  DOCS: "docs",
  DOCUMENT: "document",
  404: "404",
  INDEX: "index",
  NEW: "new",
};

const routes = [
  {
    path: "/",
    name: Page.INDEX,
    component: IndexPage,
    beforeEnter: isLoggedIn,
    meta: {
      order: 1,
      title: "Документообіг",
    },
  },
  {
    path: "/docs",
    name: Page.DOCS,
    component: DocumentListPage,
    beforeEnter: auth,
    meta: {
      order: 0,
      title: "Документи",
    },
  },
  {
    path: "/docs/:docId",
    name: Page.DOCUMENT,
    component: DocumentPage,
    beforeEnter: auth,
    meta: {
      order: 0,
      title: "Документ",
    },
  },
  {
    path: "/new",
    name: Page.NEW,
    component: NewDocumentPage,
    beforeEnter: auth,
    meta: {
      order: 0,
      title: "Створення документу",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: Page["404"],
    component: Lost,
    meta: {
      order: 1,
      title: "Сторінку не знайдено",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
