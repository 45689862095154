import dayjs from "@/modules/dayjs";

export function caps(value) {
  if (!value) return value;
  value = value.toString().toLowerCase();
  return value
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function code(value) {
  if (!value) return "";
  const r = /^([0-9]{10})$/.test(value);
  return r ? `ІНН ${value}` : `код ${value}`;
}

export function org(value) {
  const dic = {
    fop: "ФОП",
    fiz: "",
  };
  return dic[value] ? dic[value] : "";
}

export function calendar(value) {
  if (value) {
    const d = Math.abs(dayjs(value).diff(dayjs(), "days"));
    const m = Math.abs(dayjs(value).diff(dayjs(), "month"));
    const y = Math.abs(dayjs(value).diff(dayjs(), "years"));

    if (y) {
      return dayjs(value).format("D MMMM YYYY");
    }

    if (m) {
      return dayjs(value).format("D MMMM");
    }

    if (d) {
      if (d < 7) {
        const weekday = dayjs(value).format("dddd H:mm");
        return weekday.charAt(0).toUpperCase() + weekday.substring(1);
      }
      return dayjs(value).format("D MMMM H:mm");
    }

    return dayjs(value).calendar();
  }
}

export function timestamp(value) {
  return dayjs(value).format("LLL");
}
