<template lang="pug">
.dropdown
  button#dropdownMenuButton.btn.btn-outline-primary.dropdown-toggle(
    type="button",
    data-toggle="dropdown",
    aria-haspopup="true",
    :disabled="isDisabled",
    :class="{ 'btn-outline-secondary': isDisabled}",
    :aria-expanded="isOpened ? 'true' : 'false'",
    @click.prevent="toggleDropdown",
    ref="download"
  )
    fa-icon.fa-fw.me-1(:icon="['far', 'arrow-alt-to-bottom']")
    | Завантажити
  transition(
    enter-active-class="animated fadeInDown superfaster",
    leave-active-class="animated fadeOutUp superfaster"
  )
    .dropdown-menu.show.border-0.shadow(
      v-if="isOpened"
      aria-labelledby="dropdownMenuButton",
      v-click-outside="{ exclude: ['download'], handler: 'closeDropdown'}"
    )
      a.dropdown-item(
        v-for="file in files",
        :href="file.path",
        @click.prevent="download(file.path)"
      )
        fa-icon.fa-fw.me-2(:icon="['far', file.icon]")
        | {{ file.name }}
</template>

<script>
export default {
  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
  },
  emits: ["download-file", "toggle-download-dropdown", "close-download-dropdown"],
  methods: {
    download(path) {
      this.$emit("download-file", path);
    },
    toggleDropdown() {
      this.$emit("toggle-download-dropdown");
    },
    closeDropdown() {
      this.$emit("close-download-dropdown");
    },
  },
};
</script>
