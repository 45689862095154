<template lang="pug">
section.px-2
  section(v-for="doc in documents", :key="'doc' + doc.id")
    docs-document-list-item(:doc="doc")
  h2.p-4.text-center(v-if="documents.length === 0 && !loading") Порожня папка
  docs-document-list-item-placeholder(
    v-if="documents.length === 0 && loading",
    v-for="width in placeholder.initial",
    :width="width",
    :key="width",
    :loading="loading"
  )
  docs-document-list-item-placeholder(
    v-if="!isLast",
    v-for="width in placeholder.lazyLoad",
    :width="width",
    :key="width",
    :loading="loading",
    v-scroll="loadMoreDocuments"
  )
</template>

<script>
import { DocumentListModel } from "@/models/document";

import DocumentListItem from "@/components/DocumentListItem.vue";
import DocumentListItemPlaceholder from "@/components/DocumentListItemPlaceholder.vue";

import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    "docs-document-list-item": DocumentListItem,
    "docs-document-list-item-placeholder": DocumentListItemPlaceholder,
  },
  data() {
    return {
      loading: false,
      isLast: false,
      documents: new DocumentListModel([]).documents,
    };
  },
  computed: {
    ...mapState({ user: (state) => state.user }),
    ...mapGetters("document", ["allDocuments", "noMoreDocumentsLeft", "isDocumentListEmpty"]),
    placeholder() {
      return {
        initial: [3, 6, 3, 6, 3, 6, 3, 6, 3, 6],
        lazyLoad: [3, 6, 3],
      };
    },
  },
  created() {
    this.setFolder(this.$route.query.folder)
      .then(() => this.setFilter(this.$route.query.filter))
      .then(() => {
        if (this.isDocumentListEmpty) {
          this.fetchDocuments();
        } else {
          this.isLast = this.noMoreDocumentsLeft;
          this.documents = this.allDocuments;
        }
      });
  },
  methods: {
    ...mapActions("document", ["fetchDocumentsChunk", "setFolder", "setFilter"]),
    async fetchDocuments() {
      if (this.loading) return;

      try {
        this.loading = true;
        await this.fetchDocumentsChunk();
        this.documents = this.allDocuments;
        this.isLast = this.noMoreDocumentsLeft;
      } catch (error) {
        console.error(error);
        if (error.code === 401) {
          this.$router.push("/");
        }
      } finally {
        this.loading = false;
      }
    },
    loadMoreDocuments(evt, el) {
      if (!this.loading && !this.isLast && isVisible(el)) {
        setTimeout(() => {
          this.fetchDocuments();
        }, 300);
      }

      function isVisible(el) {
        return el.offsetTop > 0 && el.offsetTop < window.pageYOffset + window.innerHeight;
      }
    },
  },
};
</script>
