/*
 * Конвертируем Base64 в объект файла
 */
export function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], encodeURIComponent(filename), { type: mime });
}

/**
 * Converts file data to base64 string
 * @param {File} file;
 */
export async function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function Uint8ToBase64(u8Arr) {
  const CHUNK_SIZE = 0x8000; // arbitrary number

  let index = 0;
  const { length } = u8Arr;
  let result = "";
  let slice;

  while (index < length) {
    slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length));
    result += String.fromCharCode.apply(null, slice);
    index += CHUNK_SIZE;
  }

  return btoa(result);
}
